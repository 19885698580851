import { ReactNode } from "react"
import style from './sideBar.module.css'
import { View } from "components/view/view.component"

type SideBarProps ={
    open?: boolean,
    children?: ReactNode,
    onClose?: ()=>void,
}
export function SideBar({children, open }: SideBarProps){
    const root = document.documentElement
    
    if(open) root.style.setProperty('--sidebar-width', '220px')
    else root.style.setProperty('--sidebar-width', '0px')

    return <View className={`${style.SideBarComponent} ${open && style.Open}`}>
        <View className={`${style.SideBarContent} ${open && style.open}`}>
            {children}
        </View>
    </View>
}