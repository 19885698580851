import { ButtonProps as MuiButtonProps } from "@mui/material";
import { LoadingButton as MuiButton} from '@mui/lab'

type ButtonProps = {
    loading?: boolean
} & MuiButtonProps

export function Button(props: ButtonProps){
    const {loading, ...rest} = props
    return <MuiButton {...rest} loading={loading}/>
}