import { Dialog } from "components/dialog/dialog.component";
import { View } from "components/view/view.component";
import { Text } from 'components/text/text.component'
import style from './needAuthDialog.component.module.css'
import { Button } from "components/button/button.component";
import { useAuth } from "services/auth.service";

type NeedAuthDialogProps = {
    open: boolean,
    onClose?: () => void,
}

export function NeedAuthDialog({open, onClose }: NeedAuthDialogProps){
    const {logout} = useAuth()
    function onLogoutClick(){
        if(onClose) onClose()
        logout()
    }
    return <Dialog style={{borderRadius: '2rem'}} open={open} > 
    <View className={style.DialogContent}>
        <Text type='h2'>Autenticação necessária</Text>
        <Text type='lg'>Pressione 'sair' e entre novamente com login e senha.</Text>
        <View className={style.DialogActionButtons}>
            <Button variant='contained' onClick={onLogoutClick}>Sair</Button>
        </View>
    </View>
</Dialog>
}