import { Page } from "components/page/page.component";
import { Text } from "components/text/text.component";
import { View } from "components/view/view.component";
import { Navigate, useNavigation } from "services/navigation.service";
import style from './chooseCompany.module.css'
import { useAuth } from "services/auth.service";
import { Icon } from "components/icon/icon.component";
import { useGetCompaniesApi } from "api/company.api";
import { ErrorComponent } from "components/errorComponent/error.component";
import { Skeleton } from "components/skeleton";
import { useEffect } from "react";

export function ChooseCompanyPage(){

    const {data: companies, loading, error, get} = useGetCompaniesApi()
    const {user} = useAuth()
    const {search} = useNavigation()
    
    const to = search.from ?? '/dashboard'

    useEffect(()=>{
        get()
    // eslint-disable-next-line
    }, [])

    if(!user) return <Navigate to={`/login?from=${to}`} />

    if(error) return <Page><ErrorComponent /></Page>

    return <Page >
        <View className={ style.PageContent }>
            <Text type='h1'>Escolha uma empresa</Text>
            {loading ?
            <View className={style.CompaniesContainer}>
                <Skeleton className={style.CompanyItem}/>
                <Skeleton className={style.CompanyItem}/>
                <Skeleton className={style.CompanyItem}/> 
            </View>
            :
            <View className={style.CompaniesContainer}>
                {companies?.map((company, index) => <a key={index} href={`/${company.username}${to}`}><CompanyItem  id={company.id} name={company.name ?? company.id} /></a>)}
            </View>
            }
        </View>
    </Page>
}

function CompanyItem ({id, name}: {id: string, name: string}) {
    return <View className={style.CompanyItem}>
        <Icon fontSize='large'>domain</Icon>
        <Text type='h3'>{name}</Text>
    </View>
}