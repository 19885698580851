import { host } from "config/api.config"
import { IndicatorType } from "model/indicator.model"
import { useHTTPClient } from "services/httpClient.service"

const indicatorUrl = `${host}/indicator`

export function useGetIndicatorApi(id: string){
    const {data, loading, error, get} = useHTTPClient<IndicatorType>(`${indicatorUrl}/${id}`)
    return {
        data,
        loading,
        error,
        get
    }
}