export const FirebaseErrors = [
    { code: 'auth/invalid-credential', message: 'Erro ao realizar login. Verifique se email e senha estão corretos.'},
    { code: 'auth/email-already-exists', message: 'O email fornecido já está em uso por outro usuário. Cada usuário precisa ter um email exclusivo.'},
    { code: 'auth/id-token-expired', message: 'Token de identificação expirado.'},
    { code: 'auth/id-token-revoked', message: 'Token de identificação revogado.'},
    { code: 'auth/invalid-email', message: 'Endereço de email inválido.'},
    { code: 'auth/invalid-id-token', message: 'Token de identificação inválido.'},
    { code: 'auth/invalid-password', message: 'A senha precisa ser uma string com pelo menos 6 caracteres.'},
    { code: 'auth/too-many-requests', message: 'O número de solicitações excedeu o máximo permitido.'},
    { code: 'auth/user-not-found', message: 'Usuário não encontrado.'},
]