import { firebaseConfig } from 'config/firebase.config';
import {initializeApp, getApps} from 'firebase/app';
import { getAuth, sendPasswordResetEmail, verifyPasswordResetCode as firebaseVerifyPasswordResetCode } from 'firebase/auth';

const apps = getApps()
if (!apps.length) {
    initializeApp(firebaseConfig);
  }

const auth = getAuth()

export async function SendResetEmail(email: string){
    await sendPasswordResetEmail(auth, email)
}

export async function verifyPasswordResetCode(code: string){
    const result = await firebaseVerifyPasswordResetCode(auth, code)
    console.log(`Código verificado: ${result}`)
    return result
}