import { ReactNode } from "react"

type LinkProps = {
    children?: ReactNode,
    className?: string,
    href?: string,
    key?: string | number
}

export function Link({className, href, children, key}: LinkProps){
    return <a className={className} href={ href } key={key}>{children}</a>
}