export const COLOR_LIGHT = {
    bg1: '#F3F4F6',
    bg2: '#ffffff',
    bg3: '#E5E7EB',
    text_color: '#111827',
    sub_text_color: '#6B7280',
    primary_color: '#2F59A5',
    primary_color_contrast: '#EDEFF8',

    blue_bg1: '#E0F2FE',
    blue_bg2: '#F0F9FF',
    blue_bg3: '#BAE6FD',
    blue_text_color: '#0C4A6E',
    blue_sub_text_color: '#0369A1',
    blue_primary_color: '#2F59A5',
    blue_primary_color_contrast: '#EDEFF8',

    red_bg1: '#FEE2E2',
    red_bg2: '#FEF2F2',
    red_bg3: '#FECACA',
    red_text_color: '#400F0F',
    red_sub_text_color: '#991B1B',
    red_primary_color: '#DC2626',
    red_primary_color_contrast: '#FEF2F2',

    yellow_bg1: '#FEF9C3',
    yellow_bg2: '#FEFCE8',
    yellow_bg3: '#FEF08A',
    yellow_text_color: '#713F12',
    yellow_sub_text_color: '#A16207',
    yellow_primary_color: '#CA8A04',
    yellow_primary_color_contrast: '#FEFCE8',

    green_bg1: '#DCFCE7',
    green_bg2: '#F0FDF4',
    green_bg3: '#BBF7D0',
    green_text_color: '#14532D',
    green_sub_text_color: '#15803D',
    green_primary_color: '#16A34A',
    green_primary_color_contrast: '#F0FDF4',
}

export const COLOR_DARK = {
    bg1: '#111827',
    bg2: '#1F2937',
    bg3: '#374151',
    text_color: '#F9FAFB',
    sub_text_color: '#9CA3AF',
    primary_color: '#3565BF',
    primary_color_contrast: '#E5EBFF',

    blue_bg1: '#0C4A6E',
    blue_bg2: '#075985',
    blue_bg3: '#0369A1',
    blue_text_color: '#F0F9FF',
    blue_sub_text_color: '#BAE6FD',
    blue_primary_color: '#3565BF',
    blue_primary_color_contrast: '#E5EBFF',

    red_bg1: '#400F0F',
    red_bg2: '#7F1D1D',
    red_bg3: '#991B1B',
    red_text_color: '#FEF2F2',
    red_sub_text_color: '#FECACA',
    red_primary_color: '#EF4444',
    red_primary_color_contrast: '#FEF2F2',

    yellow_bg1: '#713F12',
    yellow_bg2: '#854D0E',
    yellow_bg3: '#A16207',
    yellow_text_color: '#FEFCE8',
    yellow_sub_text_color: '#FEF08A',
    yellow_primary_color: '#EAB308',
    yellow_primary_color_contrast: '#FEFCE8',

    green_bg1: '#14532D',
    green_bg2: '#166534',
    green_bg3: '#15803D',
    green_text_color: '#F0FDF4',
    green_sub_text_color: '#BBF7D0',
    green_primary_color: '#22C55E',
    green_primary_color_contrast: '#F0FDF4',
}