import { Page } from "components/page/page.component";
import { Text } from "components/text/text.component";
import style from './companies.module.css'
import { Table, TableBody, TableCell, TableHead, TableRow } from "components/table/table.component";
import { useEffect, useState } from "react";
import { useGetCompaniesApi } from "api/company.api";
import { InputAdornment } from "@mui/material";
import { View } from "components/view/view.component";
import { Icon } from 'components/icon/icon.component'
import { TextInput } from "components/textInput/textInput.component";
import { Button } from "components/button/button.component";
import { Skeleton } from "components/skeleton";
import { ErrorComponent } from "components/errorComponent/error.component";
import { removeAccentsAndSpecialChars } from "utils/stringManage.util";
import { host } from "config/host.config";

export function CompaniesPage(){
    const [search, setSearch] = useState('')
    const {data: comp, loading, error, get } = useGetCompaniesApi()

    const companies = search ? comp?.filter(_ => removeAccentsAndSpecialChars(_.name.toLowerCase()).includes(removeAccentsAndSpecialChars(search.toLowerCase())) || removeAccentsAndSpecialChars(_.username.toLowerCase()).includes(removeAccentsAndSpecialChars(search.toLowerCase())) ) : comp

    useEffect(()=>{
        getCompanies()
    // eslint-disable-next-line
    }, [])

    function getCompanies(){
        get()
        .catch(error =>{

        })
    }

    function handleSearchChange(text: string){
        setSearch(text)
    }

    function handleCompanyLinkClick(disable: boolean){
        if(disable) alert('Empresa inativa!')
    }

    function handleAtualizar(){
        getCompanies()
    }

    if(error) return <Page className={style.Page} menu header title="Empresas"> 
        <View className={style.Content}>
            <ErrorComponent >
                <Button onClick={handleAtualizar} variant='contained' >Atualizar</Button>
            </ErrorComponent>
        </View>
    </Page>

    return <Page className={style.Page} menu header title="Empresas">
        <View className={style.Content}>
            <View className={style.ContentHeader}>
                <TextInput 
                    fullWidth 
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><Icon>search</Icon></InputAdornment>,
                      }}
                    value={search}
                    onChange={(e)=> handleSearchChange(e.target.value)}
                    size='small'
                />
                <a href="/empresa">
                    <Button className={style.AddButton} variant='contained' size='medium' ><Icon primaryContrast >add_circle</Icon>Nova Empresa</Button>
                </a>
            </View>
        {loading
        ?  <Skeleton className={style.Skeleton} />
        : <View className={style.TableContainer}>
        <View className={style.TableContent}>
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell><Text type='h4'>Nome</Text></TableCell>
                            <TableCell><Text type='h4'>Site</Text></TableCell>
                            <TableCell><Text type='h4'>Ativa</Text></TableCell>
                            <TableCell><Text type='h4'>Ações</Text></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                        (!companies || companies?.length <= 0)
                        ? <TableRow className={style.Content}>
                            <TableCell colSpan={4}>
                                <Text type='sm'>Nenhuma Empresa encontrada</Text>
                            </TableCell>
                        </TableRow>
                        : companies.map(company => <TableRow key={company.username}>
                            <TableCell><Text type='sm'>{company.name}</Text></TableCell>
                            <TableCell>
                                <Text 
                                    href={!company.disable ? `/${company.username}` : undefined} 
                                    type='sm' 
                                    onClick={() => handleCompanyLinkClick(company.disable)} 
                                >
                                    {host}/{company.username}
                                </Text>
                            </TableCell>
                            <TableCell><Text type='sm'>{!company.disable ? 'Ativa' : 'Inativa'}</Text></TableCell>
                            <TableCell>
                                <View title="Editar empresa">
                                <a href={`/empresa/${company.username}`}>
                                    <Icon >edit_square</Icon>
                                </a>
                                </View>
                            </TableCell>
                        </TableRow>)
                        }
                    </TableBody>
                </Table>
            </View>
        </View>
        }
        </View>
    </Page>
}