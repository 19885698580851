import { getItem, useStorage} from 'services/storage.service'
import { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { useAuthenticationApi } from 'api/authentication.api';
import './firebase.service'
import { User } from 'model/user.model';
import { jwtDecode } from 'jwt-decode'
import { Features } from 'data/features.data';
import { useAuthorizationApi } from 'api/authorization.api';
import { AuthKey } from 'config/authorizationToken.config';
import { NeedAuthDialog } from 'components/needAuthDialog/needAuthDialog.component';

export function getAuthToken(){
  if(!AuthKey) throw new Error('Auth token key not found')
  return getItem(AuthKey)
}

type AuthContextType = {
    token: string | null,
    login: (username: string, password: string) => Promise<void>,
    logout: () => Promise<void>,
    loading: boolean,
    error: any,
    resetError: () => void,
    user: User | undefined,
    featuresAllowed: (features?: string[], needAll?: boolean) => boolean,
    showNeedAuth?: () => void
}

// Criar o contexto
const AuthContext = createContext<AuthContextType>({
    token: null,
    login: async () => {},
    logout: async () => {},
    loading: false,
    error: undefined,
    resetError: () => {},
    user: undefined,
    featuresAllowed: () => true,
    showNeedAuth: () => {}
  });

// Hook personalizado para usar o contexto
export const useAuth = () => useContext(AuthContext);

// Componente do provedor de autenticação
export const AuthProvider = ({ children }: { children: ReactNode}) => {
  if(!AuthKey) throw new Error('Auth token key not found')
  const { setItem: setToken, removeItem: removeToken, item: token } = useStorage(AuthKey)
  const [user, setUser] = useState<User | undefined>()
  const [loading, setLoading] = useState(false)
  const [showNeedAuth, setShowNeedAuth] = useState(false)
  const [error, setError] = useState<{message: string} | undefined>(undefined)
  const { login: loginApi } = useAuthenticationApi()
  const { getAuthToken } = useAuthorizationApi()

  useEffect(()=>{
    if(!token){ setUser(undefined); return; }
    const _user = getUser(token)
    setUser(_user)
  },[token])
  // Função para fazer login
  const login = async (username: string, password: string) => {
    try{
      setLoading(true)
      const idToken = await loginApi(username, password)
      const authorizationToken = await getAuthToken(idToken)
      if(authorizationToken) {
        setToken(authorizationToken)
        const _user = getUser(authorizationToken)
        setUser(_user)
      }else throw new Error('Authorization Token não encontrado')
      setLoading(false)
      // return authorizationToken
    }catch(error: any){
      setLoading(false)
      // console.log(`auth error`, error)
      let errorMessage
      if(error.response && error.response.status === 403 && error.response.data && error.response.data.message) errorMessage = error.response.data.message
      const _error = {message: errorMessage} ?? error
      setError(_error)
      return Promise.reject(_error);
    }
  };

  // Função para fazer logout
  const logout = async () => {
    if(showNeedAuth) setShowNeedAuth(false)
    removeToken();
  };

  const resetError = () => {
    setError(undefined)
  }

  function getUser(token: string) : User | undefined {
    if(!token) return
    const decodedToken= jwtDecode(token)
    const t = decodedToken as { name: string, username: string, companies?: {id: string, name?:string}[], roles?: {id: string, name?:string}[]}
    const _user: User = {
      name: t.name,
      username: t.username,
      email: t.username,
      companies: t.companies,
      roles: t.roles
    }
    return _user
  }

  function featuresAllowed(features?: string[], needAll?: boolean): boolean {
      // console.log(`Features allowed? :`, user, user?.roles, features)
      if(!features || features.length <= 0) return true
      
      if (!user) return false; // Se não houver usuário logado, retorna false

      if(!user.roles) return false

      for (const feature of features) {
        if(needAll) {
          if (!Features.find(_ => _.id === feature) || !user.roles.every(role => Features.find(_ => _.id === feature)?.rolesAllowed.find(_ => _.id === role.id))) {
              return false; // Se o usuário não tiver uma das roles permitidas para qualquer feature, retorna false
          }
        } else {
          if (user.roles.some(role => Features.find(_ => _.id === feature)?.rolesAllowed.find(_ => _.id === role.id))) {
            return true; // Se o usuário tiver pelo menos uma das roles permitidas, retorna true
          }
        }
      }

      return !!needAll
  }

  function _showNeedAuth(){
    setShowNeedAuth(true)
  }

  return (
    <AuthContext.Provider value={{ token , login, logout, loading, error, resetError, user: user, featuresAllowed, showNeedAuth: _showNeedAuth }}>
      {children}
      <NeedAuthDialog open={showNeedAuth} />
    </AuthContext.Provider>
  );
};
