import { ReactNode, useState } from 'react'
import style from './page.component.module.css'
import { Header } from 'components/header/header.component'
import { SideMenu } from 'components/sideMenu/sideMenu.component'

type PageProps = {
    children: ReactNode,
    className?: string,
    title?: string,
    header?: boolean,
    menu?: boolean
}
export function Page({ className, children, menu, header, title}: PageProps) {
    const [open, setOpen] = useState(menu ? true : false)
    
    return <div className={`${style.Page} ${className && className}`}>
        {menu && <SideMenu open={open} />}
        <div className={`${style.Content} ${open ? style.Open : ''} ${className && className}`}>
            {header && <Header title={title} onMenuClick={()=>setOpen(!open)}/>}
            {children}
        </div>
    </div>
}