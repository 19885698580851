import { Icon, IconProps } from "@mui/material";
import style from './icon.module.css'

type _IconProps = {
    primaryContrast?: boolean
} & IconProps

function _Icon({primaryContrast, ...props}: _IconProps){
    return <Icon className={`${style.Icon} ${props.onClick !== undefined ? style.Pressable: ''} ${primaryContrast && style.PrimaryContrast} ${props.className && props.className}`} {...props} />
}

export { _Icon as Icon }