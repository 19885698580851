import { useState, useEffect } from 'react';

export const getItem = (key: string) => {
    try {
      const item = localStorage.getItem(key);
      return item
    } catch (error) {
      console.error(`Error getting item ${key} from localStorage:`, error);
      return null;
    }
  };

export function useStorage(key: string) {

    const [storedItem, setStoredItem] = useState(getItem(key));

  // Função para definir um item no localStorage
  const setItem = (value: string) => {
    try {
      localStorage.setItem(key, value);
      setStoredItem(value);
    } catch (error) {
      console.error(`Error setting item ${key} in localStorage:`, error);
    }
  };

  // Função para remover um item do localStorage
  const removeItem = () => {
    try {
      localStorage.removeItem(key);
      setStoredItem(null);
    } catch (error) {
      console.error(`Error removing item ${key} from localStorage:`, error);
    }
  };

  useEffect(() => {
    // Listener para ouvir mudanças em outras abas
    const listener = (event: StorageEvent) => {
      if (event.storageArea === localStorage && event.key === key) {
        if(event.newValue) setItem(event.newValue);
        else removeItem()
      }
    }

    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  // eslint-disable-next-line
  }, []);

  return {item: storedItem, setItem, removeItem };
}

export default useStorage;
