import { ReactNode, useEffect, useState } from "react";
import { ThemeProvider as MuiThemeProvider, createTheme } from "@mui/material";
import { COLOR_DARK, COLOR_LIGHT } from "data/colors.data";

export function useColorTheme(){
    const [isDarkMode, setIsDarkMode] = useState(false);
    useEffect(() => {
        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        setIsDarkMode(darkModeMediaQuery.matches);
    
        const handleDarkModeChange = (e: { matches: boolean | ((prevState: boolean) => boolean); }) => {
          setIsDarkMode(e.matches);
        };
    
        darkModeMediaQuery.addEventListener('change', handleDarkModeChange);
    
        return () => {
          darkModeMediaQuery.removeEventListener('change', handleDarkModeChange);
        };
      }, []);

      return {
        darkMode: isDarkMode,
        COLOR: isDarkMode ? COLOR_DARK : COLOR_LIGHT
      }
}

const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        // light: '#757ce8',
        main: COLOR_LIGHT.primary_color,
        // dark: '#3565BF',
        contrastText: COLOR_LIGHT.primary_color_contrast,
      }
    },
    components: {
      MuiIcon: {
        defaultProps: {
          baseClassName: 'material-symbols-outlined'
        }
      }
    }
  })
  
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        // light: '#757ce8',
        main: COLOR_DARK.primary_color,
        // dark: '#3565BF',
        // contrastText: '#F0F9FF',
        contrastText: COLOR_DARK.primary_color_contrast,
      }
    },
    components: {
      MuiIcon: {
        defaultProps: {
          baseClassName: 'material-symbols-outlined'
        }
      }
    }
  })

type ColorThemeProviderProps = {
    children: ReactNode
}

export function ColorThemeProvider({children}: ColorThemeProviderProps){
    const { darkMode } = useColorTheme()

    return <MuiThemeProvider theme={darkMode ? darkTheme : theme}>
        {children}
    </MuiThemeProvider>
}