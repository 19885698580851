import { Page } from "components/page/page.component"
import { View } from "components/view/view.component"
import { Text } from 'components/text/text.component'
import style from './buildingGroup.module.css'
import { TextInput } from "components/textInput/textInput.component"
import { Skeleton } from "components/skeleton"
import { useEffect, useState } from "react"
import { Select, SelectItem } from "components/select/select.component"
import { Button } from "components/button/button.component"
import { useGetBuildingsApi } from "api/building.api"
import { useNavigation } from "services/navigation.service"
import { useGetBuildingGroupApi, useSaveNewBuildingGroupApi, useUpdateBuildingGroupApi } from "api/buildingGroup.api"

type BuildingGroupPageProps = {
    companyUsername?: string,
    buildingGroupId?: string
}
export function BuildingGroupPage({companyUsername, buildingGroupId}: BuildingGroupPageProps){
    
    const {goBack} = useNavigation()
    const [name, setName] = useState('')
    const [buildings, setBuildings] = useState<string[]>([])
    // const [loading, setLoading] = useState(false)

    const {data: buildingsData, loading: loadingBuildings, get: getBuildings} = useGetBuildingsApi(companyUsername ?? '')
    const {loading:loadingBuildingGroup, get} = useGetBuildingGroupApi(buildingGroupId ?? '')
    const {save: saveNew, loading } = useSaveNewBuildingGroupApi()
    const {update, loading: loadingUpdate} = useUpdateBuildingGroupApi(buildingGroupId)

    useEffect(()=>{
        getBuildings(companyUsername).catch(()=>{})
        if(buildingGroupId) get()
        .then(b => {
            setName(b?.name ?? '')
            setBuildings(b?.buildings.map(_ => _.id) ?? [])
        })
        .catch(()=>{alert('Erro ao obter dados')})
    // eslint-disable-next-line
    },[])

    function handleNameChange(newName: string){
        setName(newName)
    }

    function handleBuildingsChange(_buildings: any){
        setBuildings(_buildings)
    }

    function handleGoBack(){
        goBack()
    }

    function saveNewGroup(group: any){
        saveNew(group)
        .then(() => {
            alert('Grupo adicionado com sucesso')
            goBack()
        })
        .catch(error => {
            alert('Erro ao adicionar grupo')
        })
    }

    function updateGroup(group: any){
        if(!buildingGroupId) return
        update(group)
        .then(() => {
            alert('Grupo editado com sucesso')
            goBack()
        })
        .catch(error => {
            alert('Erro ao editar grupo')
        })
    }

    function handleSave(){
        if(!name) { alert('Insira um nome para o grupo'); return; }
        if(buildings.length <= 0) { alert('Selecione ao menos um prédio'); return; }
        const group = {
            name: name,
            companyId: companyUsername,
            buildingsIds: buildings
        }
        if(!buildingGroupId) saveNewGroup(group)
        else updateGroup(group)
    }
    
    return <Page menu header title='Grupo de Prédios'>
        <View className={style.Content}>
            <View className={style.FormContainer}>
                <View className={style.Form}>

                    <View className={style.FormItemContainer}>
                        <Text type='h4'>Nome</Text>
                        {loading || loadingBuildingGroup || loadingUpdate
                        ? <Skeleton color='bg3'/>
                        : <TextInput size='small' fullWidth value={name} onChange={e => handleNameChange(e.target.value)}/>}
                    </View>

                    <View className={style.FormItemContainer}>
                        <Text type='h4'>Prédios</Text>
                        {
                            (loading || loadingBuildings || loadingBuildingGroup || loadingUpdate)
                            ? <Skeleton color='bg3'/>
                            : <Select multiple size='small' value={buildings} onChange={e => handleBuildingsChange(e.target.value)}>
                                {buildingsData?.map((b, index) => <SelectItem key={index} value={b.id}>{b.name}</SelectItem> )}
                            </Select>
                        
                        }
                    </View>
                </View>
            </View>
            <View className={style.Buttons}>
                <Button onClick={handleGoBack}>Voltar</Button>
                <Button disabled={loading || loadingBuildingGroup || loadingUpdate} loading={loading || loadingUpdate} variant='contained' onClick={handleSave}>Salvar</Button>
            </View>
        </View>
    </Page>
}