import { host } from "config/api.config";
import { useHTTPClient } from "services/httpClient.service";

const authorizationTokenUrl = `${host}/authorization/token`

export function useAuthorizationApi(){
    const {loading, error, post} = useHTTPClient<string>(authorizationTokenUrl)

    async function getAuthToken(idToken: string): Promise<string> {
        return await post({data: {idToken}})
    }

    return {
        loading,
        error,
        getAuthToken
    }
}