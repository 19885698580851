import { InputAdornment } from "@mui/material"
import { Button } from "components/button/button.component"
import { Page } from "components/page/page.component"
import { Skeleton } from "components/skeleton"
import { TextInput } from "components/textInput/textInput.component"
import { View } from "components/view/view.component"
import { Text } from 'components/text/text.component'
import {Table, TableHead, TableBody, TableRow, TableCell } from 'components/table/table.component'
import { Icon } from "components/icon/icon.component"
import style from './buildingGroups.module.css'
import { useEffect, useState } from "react"
import { removeAccentsAndSpecialChars } from "utils/stringManage.util"
import { useDeleteBuildingGroupApi, useGetBuildingGroupsApi } from "api/buildingGroup.api"
import { Link } from "components/link/link.component"

type BuildingGroupsPageProps = {
    companyUsername?: string
}
export function BuildingGroupsPage({companyUsername}: BuildingGroupsPageProps){
    
    const [search, setSearch] = useState('')
    const {data: groups, loading, get} = useGetBuildingGroupsApi(companyUsername ?? '')
    const { delete: _delete } = useDeleteBuildingGroupApi()

    const filteredGroups = search ? groups?.filter(_ => 
        removeAccentsAndSpecialChars(_.name?.toLowerCase() ?? '').includes(removeAccentsAndSpecialChars(search.toLowerCase()))
    ) : groups

    useEffect(()=>{
        get().catch(()=>{})
    // eslint-disable-next-line
    },[])

    function handleSearchChange(_search: string){
        setSearch(_search)
    }

    function handleDeleteGroup(id: string){
        _delete(id)
        .then(()=>{
            alert(`Grupo deletado com sucesso`)
            get().catch(()=>{})
        })
        .catch(error => {
            alert(`Erro ao deletar grupo de prédios. Tente novamente ou entre em contato com o suporte.`)
        })
    }

    return <Page menu header title='Grupos de Prédios'>
        <View className={style.Content}>
            <View className={style.ContentHeader}>
                <TextInput 
                    fullWidth 
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><Icon>search</Icon></InputAdornment>,
                      }}
                    value={search}
                    onChange={(e)=> handleSearchChange(e.target.value)}
                    size='small'
                />
                <a href={`/${companyUsername}/grupo-de-predios`}>
                    <Button className={style.AddButton} variant='contained' size='medium' ><Icon primaryContrast >add_circle</Icon>Novo Grupo</Button>
                </a>
            </View>
        {loading
        ?  <Skeleton className={style.Skeleton} />
        : <View className={style.TableContainer}>
        <View className={style.TableContent}>
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell><Text type='h4'>Nome</Text></TableCell>
                            <TableCell><Text type='h4'>Prédios</Text></TableCell>
                            <TableCell><Text type='h4'>Ações</Text></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                        (!filteredGroups || filteredGroups?.length <= 0)
                        ? <TableRow className={style.Content}>
                            <TableCell colSpan={7}>
                                <Text type='sm'>Nenhum Grupo encontrado</Text>
                            </TableCell>
                        </TableRow>
                        : filteredGroups.map((group, index) => <TableRow key={index}>
                            <TableCell><Text type='sm'>{group.name}</Text></TableCell>
                            <TableCell><Text type='sm'>{group.buildings?.map((_: { name: any }) => _.name).join(', ') ?? ''}</Text></TableCell>
                            <TableCell>
                                <View className={ style.TableActionsIcons}>
                                    <View className={style.TableActionsIcon} title="Editar grupo de prédios">
                                    <Link className={style.TableActionsIcon} href={`/${companyUsername}/grupo-de-predios/${group.id}`}>
                                        <Icon >edit_square</Icon>
                                    </Link>
                                    </View>
                                    <View className={style.TableActionsIcon} onClick={() => {handleDeleteGroup(group.id)}} title="Deletar grupo de prédios">
                                    <Icon>delete</Icon>
                                    </View>
                                </View>
                            </TableCell>
                        </TableRow>)
                        }
                    </TableBody>
                </Table>
            </View>
        </View>
        }
        </View>
    </Page>
}