import { ROLE } from "data/userRoles.data";
import { ReactNode } from "react";
import { useAuth } from "services/auth.service";
import { Navigate, useNavigation } from "services/navigation.service";

type ProtectedRouteProps = {
    children?: ReactNode ,
    company?: string,
    features?: string[],
    needAllFeatures?: boolean,
    needCompany?: boolean,
    redirectToWhenNotAllowed?: ReactNode
}

export function ProtectedRoute({children, company, features, needCompany, needAllFeatures, redirectToWhenNotAllowed}:ProtectedRouteProps){
    const { user, featuresAllowed } = useAuth()
    const { path , search, searchString } = useNavigation()
    const { from } = search

    const completePath = `${path}${encodeURIComponent(searchString)}`

    function companyAllowed(): boolean{
        if(!user) return false

        if(company && user.roles?.find(_ => _.id === ROLE.admin.id)) return true

        if(company && (!user?.companies || user.companies.length <= 0  || !user.companies.find(_ => _.id === company))) return false

        return true
    }

    function defineCompany() {
        if(!user) return <Navigate to={`/login${completePath ? `?from=${completePath}` : ''}`} replace />

        if(user.roles?.find(_ => _.id === ROLE.admin.id)) return <Navigate to={`/escolha-empresa?from=${completePath}`} replace />

        if(!user.companies || user.companies.length <= 0) return <Navigate to={`/nao-autorizado${completePath ? `?from=${completePath}`: ''}`} replace />

        if(!user.companies.at(0)) return <Navigate to={`/nao-autorizado${completePath ? `?from=${completePath}`:''}`} replace />
        
        return <Navigate to={`/${user.companies[0].id}${completePath ?? ''}`} replace />
    }

    if(!user) return <Navigate to={`/login${from ? `?from=${from}` : completePath ?`?from=${completePath}`: ''}`} replace />

    if(needCompany && !company) return defineCompany()

    if(!featuresAllowed(features, needAllFeatures) || !companyAllowed()) {
        if(redirectToWhenNotAllowed) return <>{redirectToWhenNotAllowed}</>
        return <Navigate to={`/nao-autorizado${completePath ? `?from=${completePath}`:''}`} replace />
    }

    return <>{children}</>
}