import { Text } from 'components/text/text.component'

export function removeAccentsAndSpecialChars(str: string): string {
    const normalizedStr = str.normalize('NFD').replace(/[\u0300-\u036F]/g, '');
    const onlyLettersAndNumbers = normalizedStr.replace(/[^a-zA-Z0-9]/g, '');
    return onlyLettersAndNumbers;
  }

export function textWithLineBreaks(text: string) {
  return (
    <div>
      {text.split('\n').map((line, index) => (
        <Text type='sm' key={index}>
          {line}
          {index < text.split('\n').length - 1 && <br />}
        </Text>
      ))}
    </div>
  );
}

export function arraysEqual(arr1: string[], arr2: string[]): boolean {
  if (arr1.length !== arr2.length) {
      return false;
  }
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();
  for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
          return false;
      }
  }
  return true;
}