import { BarElement, CategoryScale, Chart, ChartData, Filler, Legend, LineElement, LinearScale, PointElement, Title, Tooltip, ChartOptions } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { View } from "components/view/view.component";
import { Text } from 'components/text/text.component'
import { Building } from "model/bulding.model";
import { IndicatorRecordType } from "model/indicatorRecord.model";
import { Bar, Line } from "react-chartjs-2";
import { useColorTheme } from "services/theme.service";
import { colorWithTransparency, generateRandomHexColor } from "utils/color.util";
import { sortByDate, toMonthYearSmallString } from "utils/date.util";
import style from './gruphic.component.module.css'
// import { format } from "date-fns";

Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

type GraphicProps = {
    indicators: IndicatorRecordType[],
    type: 'bar' | 'line',
    groupBy?: 'month' | 'building',
    showLegends?: boolean
}

export function Graphic({indicators, type, groupBy = 'month', showLegends}: GraphicProps){
    const { COLOR } = useColorTheme()

    const _maxValue = Math.max(...indicators.map(_ => _.value ?? 0))
    // const suggestedMax = indicators.at(0)?.indicator.unit === '%' ? 110 : _maxValue * 1.05
    const suggestedMax = _maxValue * 1.15

    function formatDateToMonth(date: Date): string {
        // const utcDate = new Date(date)
        // const brasiliaOffset = +3;
        // const _date = new Date(utcDate.getTime() + (brasiliaOffset * 60 * 60 * 1000));
        // const year = _date.getFullYear();
        // const month = String(_date.getMonth() + 1).padStart(2, '0');
        // return `${month}/${year}`;
        return toMonthYearSmallString(date)
        // return format(_date, 'MMM/yy')
    }
    
    
    function getUniqueMonths(records: IndicatorRecordType[]): string[] {
        const uniqueMonths = new Set<string>();
    
        records.sort((a,b) => { return sortByDate(new Date(a.date), new Date(b.date), true)}).forEach(record => {
            const monthKey = formatDateToMonth(record.date);
            uniqueMonths.add(monthKey);
        });
    
        return Array.from(uniqueMonths);
    }

    function getUniqueBuildings(records: IndicatorRecordType[]): {id: string, name?: string, color?: string}[] {
        const buildingMap = new Map<string, {id: string, name?: string, color?: string}>();
    
        records.forEach(record => {
            const building = record.building;
            if (!buildingMap.has(building.id)) {
                buildingMap.set(building.id, building);
            }
        });
    
        return Array.from(buildingMap.values());
    }

    interface BuildingGroupedRecord {
        building: Partial<Building>;
        indicatorRecords: IndicatorRecordType[];
    }

    interface MonthGroupedRecord {
        month: string;
        indicatorRecords: IndicatorRecordType[];
    }

    // Função para agrupar por mês da data
    function groupByDateMonth(records: IndicatorRecordType[]): MonthGroupedRecord[] {
        const groupedByMonth = records.reduce((acc, record) => {
            const monthKey = formatDateToMonth(record.date);
            if (!acc[monthKey]) {
                acc[monthKey] = [];
            }
            acc[monthKey].push(record);
            return acc;
        }, {} as Record<string, IndicatorRecordType[]>);

        return Object.entries(groupedByMonth).map(([month, indicatorRecords]) => ({
            month,
            indicatorRecords
        }))
    }
    
    // Função para agrupar por building.id
    function groupByBuildingId(records: IndicatorRecordType[]): BuildingGroupedRecord[] {
        const buildingMap = new Map<string, BuildingGroupedRecord>();
    
        records.forEach(record => {
            const building = record.building;
            if (!buildingMap.has(building.id)) {
                buildingMap.set(building.id, {
                    building: building,
                    indicatorRecords: []
                });
            }
            buildingMap.get(building.id)?.indicatorRecords.push(record);
        });
    
        return Array.from(buildingMap.values());
    }

    const months = getUniqueMonths(indicators)

    const monthsAndColors = months.map(_ => {return {month: _, color: generateRandomHexColor() }})

    const buildings = getUniqueBuildings(indicators)

    const groupByMonth = groupByDateMonth(indicators)

    const groupByBuilding = groupByBuildingId(indicators)

    const chartIndicatorData: ChartData<'bar', number[], string > = {
        labels: groupBy === 'month' ? months : buildings.map(_ => _.name ?? ''),
        datasets: groupBy === 'month' 
        ? groupByBuilding.map(_ => {
            const buildingColor = _.building.color ?? generateRandomHexColor()
            return {
                fill: true,
                label: _.building.name,
                data: months.map(month => {
                    return _.indicatorRecords.find(ir => formatDateToMonth(ir.date) === month)?.value ?? 0
                }),
                borderColor: monthsAndColors.map(month => buildingColor),
                backgroundColor: monthsAndColors.map(month => buildingColor),
            }
        })
        : groupByMonth.map(_ => {
            return {
                fill: true,
                label: _.month,
                data: buildings.map(building => {
                    return _.indicatorRecords.find(ir => formatDateToMonth(ir.date) === _.month && ir.building.id === building.id)?.value ?? 0
                }),
                borderColor: monthsAndColors.find(mc => _.month === mc.month)?.color ?? generateRandomHexColor(), //monthsAndColors.map(_ => _.color), //buildings.map(building => building.color ?? generateRandomHexColor()),
                backgroundColor: monthsAndColors.find(mc => _.month === mc.month)?.color ?? generateRandomHexColor(), //monthsAndColors.map(_ => _.color), //buildings.map(building => building.color ?? generateRandomHexColor()),
            }
        })
    }

    const lineChartIndicatorData: ChartData<'line', number[], string > = {
        labels: months,
        datasets: groupByBuilding.map(_ => {
            const buildingColor = _.building.color ?? generateRandomHexColor()
            return {
                fill: true,
                label: _.building.name,
                data: months.map(month => {
                    return _.indicatorRecords.find(ir => formatDateToMonth(ir.date) === month)?.value ?? 0
                }),
                borderColor: months.map(month => buildingColor),
                backgroundColor: months.map(month => colorWithTransparency(buildingColor, 0.3))
            }
        })
    }

    const options: ChartOptions< 'bar' | 'line' > = {
        responsive: true,
        maintainAspectRatio: false,
        plugins:{
            datalabels: {
                anchor: 'end',
                align: 'end',
                font: { weight: 'bold'},
                formatter: function (value: any) {
                    return parseFloat(value.toFixed(0)).toLocaleString('pt-BR') // Display the actual data value
                }
            },
            legend:{
                display: false,
            }
        },
        layout:{
            padding: {
                top: (type === 'line') ? undefined : 20,
            }
        },
        scales:{
            y:{
                beginAtZero: true,
                suggestedMax: suggestedMax,
                // max: suggestedMax,
                // ticks: { color: '#111827' },
                ticks: { color: COLOR.text_color },
                grid: { color: colorWithTransparency(COLOR.text_color, .2), display: true}
            },
            x: {
                ticks: {
                    color: COLOR.text_color,
                },
                grid: { display: false }
            }
        }
    }

    if(type === 'line') return <Line
        options= {options}
        data={lineChartIndicatorData}
    />
    return <View className={style.GraphicComponent}>
        <View className={style.GraphicContent}>
            <Bar 
                options= {options}
                data={chartIndicatorData}      
            />
        </View>
        <View className={style.LegendContent} hide={!showLegends}>
            {groupBy === 'month' ?
            buildings.map(_ => <View className={style.Legend}> 
                <View className={style.LegendColorBox} style={{backgroundColor: _.color}}> </View>
                <Text>{_.name}</Text>
            </View>)
            :
            monthsAndColors.map(_ => <View className={style.Legend}> 
                <View className={style.LegendColorBox} style={{backgroundColor: _.color}}> </View>
                <Text>{_.month}</Text>
            </View>
            )
            }
        </View>
    </View>
}