import { host } from "config/api.config";
import { BuildingGroup } from "model/buildingGroup.model";
import { useHTTPClient } from "services/httpClient.service";

const url = `${host}/buildingGroup`

export function useGetBuildingGroupApi(id: string){
    const {data, loading, error, get } = useHTTPClient<BuildingGroup>(`${url}/${id}`)

    async function getCompany(){
        return await get({})
    }
    return {
        loading,
        error,
        get: getCompany,
        data
    }
}

export function useGetBuildingGroupsApi(companyId: string){
    const {data, loading, error, get } = useHTTPClient<BuildingGroup[]>(`${url}`)

    async function getApi(){
        return await get({_url: `${url}?companyId=${companyId}`})
    }
    return {
        loading,
        error,
        get: getApi,
        data
    }
}

export function useSaveNewBuildingGroupApi(){
    const {loading, error, post } = useHTTPClient<BuildingGroup>(`${url}`)
    
    async function save(data: any){
        return await post({data: data})
    }
    
    return {
        loading,
        error,
        save
    }
}

export function useUpdateBuildingGroupApi(id?: string){
    const {loading, error, put } = useHTTPClient<BuildingGroup>(`${url}/${id}`)

    async function update(data: any){
        return await put({data: data})
    }

    return {
        loading,
        error,
        update
    }
}

export function useDeleteBuildingGroupApi(id?: string){
    const { _delete, loading, error } = useHTTPClient(`${url}/${id}`)

    async function deleteGroup(groupId: string){
        return _delete({_url: `${url}/${groupId}` })
    }

    return {
        loading,
        error,
        delete: deleteGroup
    }
}