import style from './indicatorRecordsTable.module.css'
import { Table, TableBody, TableCell, TableHead, TableRow } from "components/table/table.component"
import { View } from "components/view/view.component"
import { Text } from 'components/text/text.component'
import { IndicatorRecordType } from "model/indicatorRecord.model"
import { toMonthYearString } from 'utils/date.util'
import { textWithLineBreaks } from 'utils/stringManage.util'
import { FEATURE } from 'data/features.data'
import { Icon } from 'components/icon/icon.component'
import { Link } from 'components/link/link.component'

interface IndicatorRecordsTableProps {
    indicatorRecords?: IndicatorRecordType[]
    showIndicator?: boolean
    showBuilding?: boolean
    showDate?: boolean
    showValue?: boolean
    showDescription?: boolean
    showActions?: boolean
}

export function IndicatorRecordsTable({indicatorRecords, showIndicator, showBuilding, showDate, showValue, showDescription, showActions}: IndicatorRecordsTableProps) {

    return <View className={style.TableContainer}>
    <View className={style.TableContent}>
            <Table >
                <TableHead>
                    <TableRow>
                        {showIndicator && <TableCell><Text type='h4'>Indicador</Text></TableCell>}
                        {showBuilding && <TableCell><Text type='h4'>Prédio</Text></TableCell>}
                        {showDate && <TableCell><Text type='h4'>Mês/Ano</Text></TableCell>}
                        {showValue && <TableCell><Text type='h4'>Valor</Text></TableCell>}
                        {showDescription && <TableCell><Text type='h4'>Observação</Text></TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                    !indicatorRecords || indicatorRecords.length <= 0
                    ? <TableRow className={style.Content}>
                        <TableCell colSpan={5}>
                            <Text type='sm'>Nenhum Indicador encontrado</Text>
                        </TableCell>
                    </TableRow>
                    : indicatorRecords.map((indicatorRecord, index) => <TableRow key={index}>
                        {showIndicator && <TableCell><Text type='sm'>{ indicatorRecord.indicator.name ?? '---' }</Text></TableCell>}
                        {showBuilding && <TableCell><Text type='sm'>{ indicatorRecord.building.name ?? '---' }</Text></TableCell>}
                        {showDate && <TableCell><Text type='sm'>{toMonthYearString(indicatorRecord.date) ?? '---'}</Text></TableCell>}
                        {showValue && <TableCell>
                            <Text type='sm'>{indicatorRecord.value ? `${parseFloat(indicatorRecord.value.toFixed(2)).toLocaleString('pt-BR')} ${indicatorRecord.indicator.unit && `${indicatorRecord.indicator.unit}`}` : '---'}</Text>
                        </TableCell>}
                        {showDescription && <TableCell>{ textWithLineBreaks(indicatorRecord.description ?? '---') }</TableCell>}
                        {showActions && <TableCell >
                                <View className={style.TableCellActions} >
                                    <View title='Editar lançamento' features={[FEATURE.INDICATOR_RECORD_UPDATE.id]}>
                                    <Link>
                                        <Icon >edit_square</Icon>
                                    </Link>
                                    </View>
                                    <View title='Deletar lançamento' features={[FEATURE.INDICATOR_RECORD_DELETE.id]} onClick={() => {}}>
                                        <Icon >delete</Icon>
                                    </View>
                                </View>
                            </TableCell>}
                    </TableRow>)
                    }
                </TableBody>
            </Table>
    </View>
</View>
}