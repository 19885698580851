import { Page } from "components/page/page.component";
import { Text } from 'components/text/text.component'
import { View } from "components/view/view.component";
import style from './indicator.module.css'
import { Skeleton } from "components/skeleton";
import { Select, SelectItem } from "components/select/select.component";
import { TextInput } from "components/textInput/textInput.component";
import { Button } from "components/button/button.component";
import { DatePicker } from "components/datePicker/datePicker.component";
import { useEffect, useState } from "react";
import { useGetBuildingsApi } from "api/building.api";
import { useGetIndicatorApi } from "api/indicator.api";
import { useGetIndicatorRecordApi, useSaveNewIndicatorRecord, useUpdateIndicatorRecord } from "api/indicatorRecord.api";
import { useNavigation } from "services/navigation.service";
import { IndicatorRecordType } from "model/indicatorRecord.model";

type IndicatorPageProps = {
    companyId: string,
    indicatorId: string,
    indicatorRecordId?: string,

}
export function IndicatorPage({companyId, indicatorId, indicatorRecordId}: IndicatorPageProps){
    const {search} = useNavigation()
    const {predio, data} = search
    const buildingId: string | undefined = typeof predio === 'string' ? predio : undefined
    const date: string | undefined = typeof data === 'string' ? data : undefined
    // console.log(`buildingId`)
    // console.log(date, (date ? new Date(date): '').toString())

    const [loading, loadingSaveNew, laodingUpdate] = [false, false, false]
    const [building, setBuilding] = useState( buildingId ?? '')
    const [_date, setDate] = useState<Date | undefined>(date ? new Date(date) : new Date())
    const [value, setValue] = useState<number | undefined>()
    const [description, setDescription] = useState('')
    const {data:buildings, loading: loadingBuildings, get: getBuildings} = useGetBuildingsApi(companyId)
    const {get: getIndicator, data: indicator} = useGetIndicatorApi(indicatorId)
    const {get: getIndicatorRecord} = useGetIndicatorRecordApi(indicatorRecordId ?? '')
    const {save} = useSaveNewIndicatorRecord()
    const {update} = useUpdateIndicatorRecord()

    const {goBack} = useNavigation()

    useEffect(()=>{
        getIndicator({}).catch(()=>{})
        getBuildings().catch(()=>{})
        if(indicatorRecordId) getIndicatorRecord({})
            .then(ir => {
                setBuilding(ir?.building.id ?? '')

                if(ir && ir.date){

                    const date = new Date(ir.date)
                    const year = date.getUTCFullYear();
                    const month = date.getUTCMonth(); // Note que o mês é zero-indexado (0 = Janeiro)
                    // Criar uma nova data com esses valores no fuso UTC
                    const fixedDate = new Date(Date.UTC(year, month, 15));

                    // Convertê-la para o formato ISO sem a parte do tempo
                    const fixedDateString = fixedDate.toISOString().split('T')[0];

                    console.log(fixedDateString);
                    setDate(fixedDate)
                }
                setValue(ir?.value)
                setDescription(ir?.description ?? '')
            })
            .catch(()=>{})
    // eslint-disable-next-line
    },[])

    function handleValueChange(newValue: number){
        setValue(newValue)
    }

    function handleDescriptionChange(newDescription: string){
        setDescription(newDescription)
    }

    function handleGoBack(){
        goBack()
    }

    function handleSave(){
        if(!_date) return alert('Selecione uma data')
        if(value === undefined) return alert('Atribua um valor válido')

        const ir: Omit<IndicatorRecordType, 'id'> = {
            indicator: {
                id: indicatorId,
                type: "",
                name: ""
            },
            building: {
                id: building
            },
            date: _date,
            value: value,
            description: description
        }

        if(indicatorRecordId) _update(ir)
        else saveNew(ir)
    }

    function saveNew(ir: Omit<IndicatorRecordType, 'id'>){
        save(ir)
        .then((id)=>{
            alert(`Lançamento salvo com sucesso!`)
            goBack()
        })
        .catch((error)=>{
            console.log(error)
            if(error.response.data.message) alert(error.response.data.message)
            else alert('Erro ao salvar novo lançamento')
        })
    }

    function _update(ir: Omit<IndicatorRecordType, 'id'>) {
        if(!indicatorRecordId) return alert('Erro ao salvar lançamento')
        update(indicatorRecordId, ir)
        .then(()=>{
            alert('Lançamento salvo com sucesso!')
            goBack()
        })
        .catch(()=>{alert('Erro ao salvar lançamento')})
    }

    return <Page title={indicator ? indicator.name : 'Indicador'} menu header >
        <View className={style.Content}>
            <View className={style.FormContainer}>
                <View className={style.Form}>
                    
                    <View className={style.FormItemContainer}>
                        <Text type='h4'>Prédio</Text>
                        {loading || loadingBuildings
                        ? <Skeleton color='bg3'/>
                        : <Select disabled={loadingSaveNew || laodingUpdate || !!indicatorRecordId } size='small' fullWidth value={building} onChange={e => setBuilding(e.target.value)} >
                            {buildings?.map(_ => <SelectItem key={_.id} value={_.id}>{_.name}</SelectItem>)}
                        </Select>
                        }
                    </View>
                    
                    <View className={style.FormItemContainer}>
                        <Text type='h4'>Mês/Ano</Text>
                        {loading 
                        ? <Skeleton color='bg3'/>
                        : <DatePicker views={['month', 'year']} sx={{width: '100%'}} disabled={!!indicatorRecordId} value={_date} onMonthChange={_ => setDate(_)} onYearChange={_ => setDate(_)} />}
                    </View>
                    
                    <View className={style.FormItemContainer}>
                        <Text type='h4'>{indicator?.type !== 'simple-division' ? `Valor${indicator?.unit ? ` (${indicator?.unit})` : ''}:` : `${indicator.indicator1?.name} (${indicator.indicator1?.unit}):`}</Text>
                        {loading 
                        ? <Skeleton color='bg3'/>
                        : <TextInput type='number' disabled={loadingSaveNew || laodingUpdate } size='small' value={value} onChange={e => handleValueChange(parseFloat(e.target.value))} />
                        }
                    </View>

                    <View className={style.FormItemContainer} hide={indicator?.type !== 'simple-division'}>
                        <Text type='h4'>{indicator?.type !== 'simple-division' ? 'Valor Denominador:' : `${indicator.indicator2?.name}${indicator.indicator2?.unit ? `(${indicator.indicator2?.unit})` : ''}:`}</Text>
                        {loading 
                        ? <Skeleton color='bg3'/>
                        : <TextInput type='number' disabled={loadingSaveNew || laodingUpdate} size='small' value={value} onChange={e => handleValueChange(parseFloat(e.target.value))} />
                        }
                    </View>

                    <View className={style.FormItemContainer}>
                        <Text type='h4'>Observação:</Text>
                        {loading 
                        ? <Skeleton color='bg3'/>
                        : <TextInput multiline rows={4} disabled={loadingSaveNew || laodingUpdate} size='small' value={description} onChange={e => handleDescriptionChange(e.target.value)} />
                        }
                    </View>
                </View>
            </View>
            <View className={style.Buttons}>
                <Button onClick={handleGoBack}>Voltar</Button>
                <Button disabled={loading} loading={loadingSaveNew || laodingUpdate} variant='contained' onClick={handleSave}>Salvar</Button>
            </View>
        </View>
    </Page>
}