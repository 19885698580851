import { Button } from 'components/button/button.component';
import style from './company.module.css'
import { Page } from "components/page/page.component";
import { Text } from "components/text/text.component";
import { TextInput } from 'components/textInput/textInput.component';
import { View } from 'components/view/view.component';
import { Skeleton } from 'components/skeleton'
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigation } from 'services/navigation.service';
import { useGetCompanyApi, useSaveNewCompanyApi, useUpdateCompanyApi } from 'api/company.api';
import { Company } from 'model/company.model';
import { Switch } from 'components/switch/switch.component';
import { ErrorComponent } from 'components/errorComponent/error.component';
import { removeAccentsAndSpecialChars } from 'utils/stringManage.util';

type CompanyPageProps = {
    companyId?: string
}

export function CompanyPage({ companyId }: CompanyPageProps){
    const { loading: companyLoading, error: companyError, get: getCompany} = useGetCompanyApi(companyId ?? 'error')
    const {save: saveNewCompanyApi, loading: loadingSaveNewCompany} = useSaveNewCompanyApi()
    const {save: updateCompanyApi, loading: loadingUpdateCompany} = useUpdateCompanyApi(companyId ?? 'error')

    const [name, setName] = useState('')
    const [username, setUsername] = useState('')
    const [active, setActive] = useState(true)

    useEffect(()=>{
        if(companyId) getCompany().then((c) => {
            if(!c) return
            setName(c.name)
            setUsername(c.username)
            setActive(!c.disable)
        })
        .catch(error => { 

        })
    // eslint-disable-next-line
    },[companyId])

    const {navigate} = useNavigation()

    function handleNameChange(newName: string){
        setName(newName)
    }

    function handleUsernameChange(newUsername: string){
        setUsername(removeAccentsAndSpecialChars(newUsername.toLowerCase().replace(/ /g, "-")))
    }

    function handleGoBack(){
        navigate('/empresas')
    }

    function handleActiveChange(e: ChangeEvent<HTMLInputElement>){
        setActive(e.target.checked)
    }

    function addNewCompany(_company: Omit<Company,'id'>){
        saveNewCompanyApi(_company)
        .then(()=>{
            alert('Empresa criada com sucesso')
            navigate('/empresas')
        })
        .catch(error => {
            alert(`Erro ao salvar nova empresa`)
        })
    }

    function updateCompany(_company: Omit<Company,'id'>){
        updateCompanyApi(_company)
        .then(() => {
            alert('Empresa editada com sucesso')
            navigate('/empresas')
        })
        .catch(error => {
            alert(`Erro ao salvar empresa ${companyId}`)
        })
    }

    async function checkFields(_company:Omit<Company,'id'>){
        if(!_company.name) throw new Error('Preencha o nome da empresa.')
        if(!_company.username) throw new Error('Preencha o nome de usuário da empresa.')
    }

    function handleSave(){
        const _company: Omit<Company,'id'> = {
            name: name,
            username: username,
            disable: !active
        }

        checkFields(_company)
        .then(()=>{
            if(companyId){
                updateCompany(_company)
            }else{
                addNewCompany(_company)
            }
        })
        .catch(error => {
            alert(error.message)
            return
        })
        
    }

    if(companyError) return <Page className={style.Page} menu header title='Empresa'> 
        <ErrorComponent >
            <Button onClick={handleGoBack} >Voltar</Button>
        </ErrorComponent>
    </Page>

    return <Page className={style.Page} menu header title='Empresa'>
        <View className={style.Content}>
            <View className={style.FormContainer}>
                <View className={style.Form}>
                    <View className={style.FormItemContainer}>
                        <View className={style.DisableContainer}>
                            <Text type='h4'>Ativa</Text>
                            <Switch checked={active} onChange={handleActiveChange}/>
                        </View>
                    </View>
                    <View className={style.FormItemContainer}>
                        <Text type='h4'>Nome</Text>
                        {companyLoading 
                        ? <Skeleton color='bg3'/>
                        : <TextInput disabled={loadingSaveNewCompany || loadingUpdateCompany} size='small' fullWidth value={name} onChange={e => handleNameChange(e.target.value)}/>}
                    </View>
                    
                    <View className={style.FormItemContainer}>
                        <Text type='h4'>Username</Text>
                        <View className={style.UsernameContainer}>
                            <Text type='md'>https://arotech.app/</Text>
                            {companyLoading 
                            ? <Skeleton color='bg3'/>
                            : <TextInput disabled={loadingSaveNewCompany || loadingUpdateCompany} size='small' fullWidth value={username} onChange={e => handleUsernameChange(e.target.value)} />
                            }
                        </View>
                    </View>
                    
                </View>
            </View>
            <View className={style.Buttons}>
                <Button onClick={handleGoBack}>Voltar</Button>
                <Button disabled={companyLoading} loading={loadingSaveNewCompany || loadingUpdateCompany} variant='contained' onClick={handleSave}>Salvar</Button>
            </View>
        </View>
    </Page>
}