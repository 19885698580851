import { host } from "config/api.config"
import { DashboardDataType } from "model/dashboardData.model"
import { DashboardFilter } from "model/dashboardFilters.model"
import { useHTTPClient } from "services/httpClient.service"

const dashboardUrl = `${host}/dashboard`

export function useGetDashboardDataApi(id: string){
    const {data, loading, error, get} = useHTTPClient<DashboardDataType>(`${dashboardUrl}`)
    async function getApi(filter: DashboardFilter){
        const jsonString = JSON.stringify(filter);
        const queryString = encodeURIComponent(jsonString);
        const _filter = `?filter=${queryString}`
        return await get({_url: `${dashboardUrl}${_filter}`})
    }
    return {
        data,
        loading,
        error,
        get: getApi
    }
}