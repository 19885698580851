import { Page } from "components/page/page.component"
import style from './updatePassword.module.css'
import { useNavigation } from "services/navigation.service"
import { View } from "components/view/view.component"
import logo from 'assets/images/ARO Tech - logo vetor.png'
import { Text } from "components/text/text.component"
import { TextInput } from "components/textInput/textInput.component"
import { Button } from "components/button/button.component"
import { useEffect, useState } from "react"
import { useAuth } from "services/auth.service"
import { useAuthenticationApi } from "api/authentication.api"

export function UpdatePasswordPage(){
    const { token, logout } = useAuth()
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const {search, navigate} = useNavigation()
    const { resetPasswordWithToken } = useAuthenticationApi()

    const confirmationNewPasswordError = (!newPassword && !newPasswordConfirm) ? false : (newPassword !== newPasswordConfirm)
    const atLeast6Digits = (!newPassword && !newPasswordConfirm) ? false : newPassword.length < 6

    useEffect(()=>{
        if(!search.oobCode && !token) navigate('/login')
    },[search, token, navigate])
    

    async function handleSaveNewPassword() {
        if((!newPassword && !newPasswordConfirm)) { alert('Informe uma senha válida'); return }
        if(atLeast6Digits) return
        if(confirmationNewPasswordError) return
        if(search.oobCode){
            setLoading(true)
            resetPasswordWithToken(search.oobCode as string, newPassword)
            .then(()=>{
                alert('Senha atualizada com sucesso')
                if(token) logout()
                navigate('/login')
            })
            .catch(error => {
                // console.error(error)
                setError(error)
            })
            .finally(()=>{
                setLoading(false)
            })
        }
        else{
            alert('Erro ao salvar nova senha.')
        }
    }

    function handleGoBackButton(){
        navigate('/')
    }

    function handleNewPasswordChange(pass: string){
        setNewPassword(pass)
    }

    function handleNewPasswordConfirmChange(pass: string){
        setNewPasswordConfirm(pass)
    }

    return <Page className={style.Page}>
        <View className={style.Box}>
            <View className={style.Content}>
                <View className={style.ContentHeader}>
                    <img className={style.LogoImage} src={logo} alt="Logo ARO Tech" />
                </View>
                <View className={style.ContentBody}>
                    <View className={style.ContentBodySection}>
                        <Text type="h1" weight="medium">Redefinir Senha</Text>
                        <Text className={style.Subtitle} type='lg'>Insira sua nova senha e confirme para salvar.</Text>
                    </View>
                    <View className={style.ContentBodySection}>
                        {error && <View className={style.ContentContainer}>
                            <Text className={style.ErrorMessage} type='h4' weight='medium'>Erro ao redefinir senha</Text>
                        </View>}
                        <View className={style.ContentContainer}>
                            <Text type='h3' weight='medium'>Nova Senha</Text>
                            <TextInput disabled={loading} fullWidth placeholder="digite aqui sua nova senha" type='password' value={newPassword} onChange={e => handleNewPasswordChange(e.target.value)} />
                            {atLeast6Digits && <Text className={style.ErrorMessage} type='h4' weight='medium'>A senha deve ter no mínimo 6 dígitos.</Text>}
                        </View>
                        <View className={style.ContentContainer}>
                            <Text type='h3' weight='medium'>Confirme Nova Senha</Text>
                            <TextInput disabled={loading} fullWidth placeholder="digite aqui sua nova senha novamente" type='password' value={newPasswordConfirm} onChange={e => handleNewPasswordConfirmChange(e.target.value)} />
                            {confirmationNewPasswordError && <Text className={style.ErrorMessage} type='h4' weight='medium'>Confirmação de senha incorreta.</Text>}
                        </View>
                        <View className={style.Buttons}>
                            {token && <Button id={style.BackButton} variant='outlined' onClick={handleGoBackButton} > Voltar </Button>}
                            <Button id={style.SaveButton} variant='contained' onClick={handleSaveNewPassword} loading={loading}> Salvar </Button>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    </Page>
}