import { View } from "components/view/view.component"
import { Text } from 'components/text/text.component'
import style from './pieCard.module.css'
import { Skeleton } from "components/skeleton"

type PieCardProps = {
    value?: number,
    unit?: string,
    title?: string,
    className?: string,
    color?: 'default' | 'red' | 'yellow' | 'green',
    max?: number,
    key?: string | number,
    loading?: boolean,
    hoverText?: string
}
export function PieCard({value, unit, title, className, color, max, key, loading, hoverText}: PieCardProps){
    
    const _maxValue = max ? max : unit === '%' ? 100 : undefined
    const greaterThanMax = ((!_maxValue) || (!value)) ? false : value > _maxValue
    // const fullAngle = ((value ?? 0) / 100) * 360
    const angle = !_maxValue ? 0 : (value === _maxValue) ? 360 : ((value ?? 0) / _maxValue) * 360 % 360

    const bgColor = color ? color : 'default'
    const bgColorStr = bgColor === 'default' ? `var(--blue-bg1)` : `var(--${bgColor}-bg1)`
    const primaryColorStr = bgColor === 'default' ? `var(--primary-color)` : `var(--${bgColor}-primary-color)`

    if(loading) return <View className={`${className && className} ${style.Card}`} key={key}> <Skeleton className={`${className && className} ${style.Card}`} /> </View>
    return <View className={`${className && className} ${style.Card}`} key={key} title={hoverText}>
        <View className={style.CardContent}>
            <Text className={ style.PieCardTitle } type='h5'>{`${title}${unit && unit !== '%' ? ` (${unit})` : `` }`}</Text>
            <View className={style.PieContent}>
                <View className={style.PieBackground} style={{backgroundColor:`${greaterThanMax ? bgColorStr : undefined}`}}>
                    <View className={style.PieSlice} style={{backgroundImage: `conic-gradient(${primaryColorStr} 0deg ${angle}deg, transparent ${angle}deg ${360-(angle)}deg )`}}>
                        <View className={style.PieMiddle}>
                            <Text type="h3">{(value !== undefined && !Number.isNaN(value)) ? `${parseFloat(value?.toFixed((unit !== '%' && value < 1000)? 2 : 0)).toLocaleString('pt-BR')}${unit === '%' ? unit : ''}` : '---'}</Text>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    </View>
}