import { Page } from "components/page/page.component"
import { Text } from 'components/text/text.component'
import { View } from "components/view/view.component"
import logo from 'assets/images/ARO Tech - logo vetor.png'
import style from './notFound.page.module.css'
import { Image } from "components/image/image.component"
import { Button } from "components/button/button.component"
import notFoundImage from 'assets/images/not-found-error.png'
import notFoundImageDark from 'assets/images/not-found-error-dark.png'
import { useEffect, useState } from "react"

export function NotFoundPage(){
    const initialIsDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    const [isDarkMode, setIsDarkMode] = useState(initialIsDarkMode)
    const root = document.documentElement
    root.style.setProperty('--sidebar-width', '0px')
    
    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    
        const handleChange = (event: { matches: boolean | ((prevState: boolean) => boolean) }) => {
          setIsDarkMode(event.matches);
        };
    
        mediaQuery.addEventListener('change', handleChange);
    
        // Cleanup function
        return () => {
          mediaQuery.removeEventListener('change', handleChange);
        };
      }, []);

    return(
        <Page className={style.Page}>
            <View className={style.PageContent}>
                <Image src={logo} className={style.LogoImage} />
                <Image src={isDarkMode? notFoundImageDark :notFoundImage} />
                <Text type='h1'>Página não encontrada</Text>
                <Text type='md'>Lamentamos muito pelo inconveniente. Parece que você está tentando acessar uma página que está obsoleta ou que nunca existiu.</Text>
                <a className={style.GoHomeButton} href="/"><Button variant='contained'>Vá para Página Inicial</Button></a>
            </View>
        </Page>
    )
}