import { Page } from "components/page/page.component";
import { Text } from "components/text/text.component";
import { View } from "components/view/view.component";
import { useState } from "react";
import { useNavigation } from "services/navigation.service";
import style from './forgotPassword.module.css'
import logo from 'assets/images/ARO Tech - logo vetor.png'
import { TextInput } from "components/textInput/textInput.component";
import { Button } from "components/button/button.component";
import { SendResetEmail } from "services/firebase.service";

export function ForgotPasswordPage(){
    const { search } = useNavigation()
    const initialEmail = (search.email ?? '') as string
    const [email, setEmail] = useState<string>(initialEmail)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()

    function handleEmailChange(newEmail: string){
        setEmail(newEmail)
    }

    function enviarEmail(){
        if(email) {
            setLoading(true)
            SendResetEmail(email)
            .then(()=>{
                alert('Email enviado com sucesso! Siga as instruções para redefinir sua senha')
            })
            .catch(error => {
                setError(error)
            })
            .finally(()=>{
                setLoading(false)
            })
        }
    }

    function handleForgotEmail(){
        alert('Procure o administrador do sistema responsável pela sua empresa ou entre em contato com a equipe da AROTech através do email admin@arotech.app.')
    }
    
    return <Page className={style.Page}>
    <View className={style.Box}>
        <View className={style.Content}>
            <View className={style.ContentHeader}>
                <img className={style.LogoImage} src={logo} alt="Logo ARO Tech" />
            </View>
            <View className={style.ContentBody}>
                <View className={style.ContentBodySection}>
                    <Text type="h1" weight="medium">Recuperar Senha</Text>
                    <Text className={style.Subtitle} type='lg'>Informe o email cadastrado no sistema. Enviaremos todas as instruções para redefinir uma nova senha.</Text>
                </View>
                <View className={style.ContentBodySection}>
                    {error && <View className={style.ContentContainer}>
                        <Text className={style.ErrorMessage} type='h4' weight='medium'>Erro ao enviar email de recuperação de senha. Verifique se email está correto.</Text>
                    </View>}
                    <View className={style.ContentContainer}>
                        <Text type='h3' weight='medium'>Email</Text>
                        <TextInput disabled={loading} fullWidth placeholder="admin@arotech.app" value={email} onChange={e => handleEmailChange(e.target.value)} />
                        <Text type='h4' weight='semiBold' className={style.ForgotPasswordLink} onClick={handleForgotEmail} >Esqueci meu email</Text>
                    </View>
                    <Button id={style.EnviarButton} variant='contained' loading={loading} onClick={enviarEmail}>Enviar</Button>
                </View>
            </View>
        </View>
    </View>
</Page>
}