import queryString from "query-string"
import { useNavigate, Navigate as RouterNavigate, useLocation } from "react-router-dom"

export function useNavigation(){
    const _nav = useNavigate()
    const loc = useLocation()

    function navigate(to: string){
        _nav(to)
    }

    function goBack(){
        _nav(-1)
    }

    return {
        navigate: navigate,
        goBack: goBack,
        path: loc.pathname,
        search: queryString.parse(loc.search),
        searchString: loc.search
    }
}

type NavigateProps = {
    to: string,
    replace?: boolean,
}

export function Navigate({to, replace}: NavigateProps){
    return <RouterNavigate to={to} replace={replace} />
}