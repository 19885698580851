import { IndicatorRecordType } from "model/indicatorRecord.model";
import { useState } from "react";
import { SheetFactory } from "services/excel.service";
import { downloadFile } from "services/file.service";
import { toMonthYearString } from "utils/date.util";

type IndicadorExcelReportProps = {
    data?: IndicatorRecordType[],
    fileName: string
}
export function IndicadorExcelReport({data, fileName}: IndicadorExcelReportProps){
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | undefined>()
    const excel = new SheetFactory('Relatório de Indicadores')

    function createReport(){
        excel.addTitleRow(['Indicador', 'Prédio', 'Data', 'Valor', 'Observação'])
        const rows = !data ? [] : data.map(_ => {
            return [_.indicator.name, 
                _.building.name,
                toMonthYearString(_.date),
                _.value ?? '---',
                _.description
            ]
        })
        excel.addRows(rows)
    }

    async function download(){
        try{
            setLoading(true)
            createReport()
            const b = await excel.finishSheet()
            const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        
            const blob = new Blob([b], { type: fileType });
            const name = `${fileName}.xlsx`;
            await downloadFile(blob, name)
        }
        catch(error){
            setError((error as Error).message)
        }
        setLoading(false)
    }

    return {
        download,
        loading,
        error
    }

}