import { format, subMonths, subYears } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export function toMonthYearString(date: Date){
    const d = new Date(date)
    const _date = new Date(d.getUTCFullYear(), d.getUTCMonth(), 15)
    const result = format(_date, 'MMMM/yyyy', { locale: ptBR })
    console.log(`to month/year`, date, _date, result)
    return result
}

export function toMonthYearSmallString(date: Date){
    const d = new Date(date)
    const _date = new Date(d.getUTCFullYear(), d.getUTCMonth(), 15)
    const result = format(_date, 'MMM/yyyy', { locale: ptBR })
    console.log(`to month/year`, date, _date, result)
    return result
}

export function getOneYearBefore(date: Date): Date {
    return subYears(date, 1)
}

export function getOneMonthBefore(date: Date): Date {
    return subMonths(date, 1)
}

export function getMonthsBefore(date: Date, sub: number): Date {
    return subMonths(date, sub)
}

export function sortByDate(a: Date, b: Date, ascending: boolean) {
    const comparison = a.getTime() - b.getTime();
    return ascending ? comparison : -comparison;
}