import style from './error.module.css'
import { View } from "components/view/view.component"
import { Text } from 'components/text/text.component'
import { ReactNode } from 'react'

type ErrorComponentProps = {
    title?: string,
    description?: string,
    className?: string,
    children?: ReactNode
}

export function ErrorComponent({title, description, className, children}:ErrorComponentProps){
    return <View className={`${style.Container} ${className}`}>
            <View className={style.Content}>
                <View className={style.ErrorContent}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="10%" viewBox="0 -960 960 960" width="9rem" fill='var(--red-primary-color)'>
                        <path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
                    </svg>
                    <Text className={style.ErrorTitle} type='h1'>{title ?? 'Oops!'}</Text>
                    <Text type='h4' subtext>{description ?? 'Erro ao obter dados. Tente novamente mais tarde.'}</Text>
                    {children && <View className={style.Buttons}>
                        {children}
                    </View>}
                </View>
            </View>
        </View>
}