import style from './building.module.css'
import { Page } from "components/page/page.component";
import { View } from "components/view/view.component";
import { Text } from 'components/text/text.component'
import { Switch } from 'components/switch/switch.component';
import { Skeleton } from 'components/skeleton';
import { TextInput } from 'components/textInput/textInput.component';
import { Button } from 'components/button/button.component';
import { ErrorComponent } from 'components/errorComponent/error.component';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigation } from 'services/navigation.service';
import { Select, SelectItem } from 'components/select/select.component';
import { useGetCompaniesApi } from 'api/company.api';
import { useGetBuildingApi, useSaveNewBuildingApi, useUpdateBuildingApi } from 'api/building.api';
import { Building } from 'model/bulding.model';
import { generateRandomHexColor } from 'utils/color.util';

type BuildingPageProps = {
    id?: string,
    companyId?: string
}

export function BuildingPage({companyId, id}:BuildingPageProps){

    const [company, setCompany] = useState<string>(companyId ?? '')
    const [active, setActive] = useState(true)
    const [name, setName] = useState('')
    const [qtElevadores, setQtElevadores] = useState(0)
    const [color, setColor] = useState(generateRandomHexColor())
    const { navigate } = useNavigation()
    const error = false

    const {data: companies, loading: companiesLoading, get: getCompanies} = useGetCompaniesApi()
    const {data: building, loading, get: getBuilding} = useGetBuildingApi(company ?? '', id ?? '' )
    const {loading: loadingSaveNew, save: saveNewBuilding} = useSaveNewBuildingApi(companyId ?? 'error')
    const {loading: laodingUpdate, save: updateBuilding} = useUpdateBuildingApi(company ?? 'error', id ?? 'error' )

    useEffect(()=>{
        getCompanies().catch(()=>{})
        getBuilding().catch(()=>{})
    // eslint-disable-next-line
    },[])

    useEffect(()=>{
        if(!building) return
        setName(building?.name)
        setColor(building.color)
        setActive(!building.disable)
        setQtElevadores(building.elevators)
        // setCompany(building.companyId)
    // eslint-disable-next-line
    },[building])

    function handleCompanyChange(companyId: string){
        setCompany(companyId)
    }

    function handleActiveChange(e: ChangeEvent<HTMLInputElement>){
        setActive(e.target.checked)
    }

    function handleNameChange(newName: string){
        setName(newName)
    }

    function handleQtElevadoresChange(newQtElevators: number){
        setQtElevadores(newQtElevators)
    }

    function handleColorChange(newColor:string){
        setColor(newColor)
    }

    function handleGoBack(){
        navigate('/predios')
    }

    async function checkFields(_building: Partial<Building>){
        if(!_building.name) throw new Error('Preencha o nome do prédio')
        if(!_building.companyId) throw new Error('Selecione uma empresa')
        if(_building.elevators && _building.elevators <= 0) throw new Error('Informe quantos elevadores o prédio tem')
        if(!_building.color) throw new Error('Selecione uma cor')
    }

    function saveNew(_building: Partial<Building>){
        saveNewBuilding(_building, company)
        .then(()=>{
            alert('Prédio criado com sucesso')
            navigate('/predios')
        })
        .catch(()=>{ alert(`Erro ao salvar novo prédio`) })
    }

    function update(_building: Partial<Building>){
        updateBuilding(_building, company, id)
        .then(()=>{
            alert('Prédio editado com sucesso')
            navigate('/predios')
        })
        .catch(()=>{ alert(`Erro ao salvar prédio`) })
    }

    function handleSave(){

        const _building= {
            companyId: company,
            name: name,
            elevators: qtElevadores,
            color: color,
            disable: !active
        }
       
        checkFields(_building)
        .then(()=>{
            if(id) update({id, ..._building})
            else saveNew(_building)
        })
        .catch((error)=>{
            alert(error.message)
            return
        })

    }

    if(error) return <Page className={style.Page} menu header title='Prédio'> 
        <ErrorComponent >
            <Button >Atualizar</Button>
        </ErrorComponent>
    </Page>
    
    return <Page menu header title="Prédio">
        <View className={style.Content}>
            <View className={style.FormContainer}>
                <View className={style.Form}>
                    
                    <View className={style.FormItemContainer}>
                        <View className={style.DisableContainer}>
                            <Text type='h4'>Ativo</Text>
                            <Switch checked={active} onChange={handleActiveChange}/>
                        </View>
                    </View>

                    <View className={style.FormItemContainer}>
                        <Text type='h4'>Empresa</Text>
                        {loading || companiesLoading
                        ? <Skeleton color='bg3'/>
                        : <Select disabled={loadingSaveNew || laodingUpdate || !!id} size='small' fullWidth value={company} onChange={e => handleCompanyChange(e.target.value)} >
                            {companies?.map(_ => <SelectItem key={_.id} value={_.id}>{_.name}</SelectItem>)}
                        </Select>
                        }
                    </View>
                    
                    <View className={style.FormItemContainer}>
                        <Text type='h4'>Nome</Text>
                        {loading 
                        ? <Skeleton color='bg3'/>
                        : <TextInput disabled={loadingSaveNew || laodingUpdate} size='small' fullWidth value={name} onChange={e => handleNameChange(e.target.value)}/>}
                    </View>
                    
                    <View className={style.FormItemContainer}>
                        <Text type='h4'>Quantidade de elevadores:</Text>
                            {loading 
                            ? <Skeleton color='bg3'/>
                            : <TextInput type='number' disabled={loadingSaveNew || laodingUpdate} size='small' value={qtElevadores} onChange={e => handleQtElevadoresChange(parseInt(e.target.value))} />
                            }
                    </View>

                    <View className={style.FormItemContainer}>
                        <Text type='h4'>Cor:</Text>
                            {loading 
                            ? <Skeleton color='bg3'/>
                            : <TextInput type='color' disabled={loadingSaveNew || laodingUpdate} size='small' value={color} onChange={e => handleColorChange(e.target.value)} />
                            }
                    </View>
                    
                    

                </View>
            </View>
            <View className={style.Buttons}>
                <Button onClick={handleGoBack}>Voltar</Button>
                <Button disabled={loading} loading={loadingSaveNew || laodingUpdate} variant='contained' onClick={handleSave}>Salvar</Button>
            </View>
        </View>
    </Page>
}