import { View } from "components/view/view.component";
import { Text } from 'components/text/text.component'
import { Graphic } from "components/graphic/graphic.component";
import style from './lineChartCard.component.module.css'
import { IndicatorRecordType } from "model/indicatorRecord.model";

interface LineChartCardProps {
    title?: string,
    indicatorRecords: IndicatorRecordType[],
    key?: string | number
}

export function LineChartCard({title, indicatorRecords, key}: LineChartCardProps){
    return <View className={style.GraphicCard} key={key}>
    <View className={style.CardContent}>
        <Text type="h5">{title ?? ''}</Text>
        <View className={style.GraphicContainer}>
            <Graphic indicators={indicatorRecords} type="line" />
        </View>
    </View>
</View>
}