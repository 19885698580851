import { Page, Text, View, Document, StyleSheet, Image, pdf } from '@react-pdf/renderer';
import logo from 'assets/images/ARO Tech - logo vetor.png'
import { IndicatorRecordType } from 'model/indicatorRecord.model';
import { useState } from 'react';
import { downloadFile } from 'services/file.service';
import { toMonthYearString } from 'utils/date.util';

const styles = StyleSheet.create({
    page: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#FFFFFF',
      color: '#000000',
      width: '100%',
      alignItems: 'center'
    },
    section: {
      marginBottom: 10,
      width: '90%'
    },
    headerTitleContent: {
      marginTop: 10,
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      minHeight: '50px',
      alignItems: 'center'
    },
    image: {
      position: 'absolute',
      width: '80px',
      aspectRatio: 2,
      marginLeft: '10px'
    },
    headerTitle: {
      width: '100%',
      textAlign: 'center',
      fontSize: 18
    },
    table: {
      marginTop: 10,
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    tableHeader: {
      backgroundColor: '#F3F4F6',
      fontSize: 14,
      fontWeight: 'bold',
      width: '100%',
      display: 'flex',
      flexDirection: 'row'
    },
    tableBody: {
      fontSize: 10,
      fontWeight: 'bold',
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%'
    },
    tableCell: {
      flex: 1,
      width: '100%',
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'center',
      border: '1px solid #000000',
      padding: 5
    },
  });

type IndicatorReportProps = {
    data?: IndicatorRecordType[]
}

export function IndicatorPDFReport({data}: IndicatorReportProps) {
    return <Document style={{ width: '100%' }}>
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                <View style={styles.headerTitleContent}>
                    <Image src={logo} style={styles.image} />
                    <Text style={styles.headerTitle}>Relatório de Indicadores</Text>
                </View>
            </View>

            <View style={styles.section}>
                <View style={styles.table}>
                    <View style={styles.tableHeader}>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableCell}>Indicador</Text>
                            <Text style={styles.tableCell}>Prédio</Text>
                            <Text style={styles.tableCell}>Data</Text>
                            <Text style={styles.tableCell}>Valor</Text>
                            <Text style={styles.tableCell}>Observação</Text>
                        </View>
                    </View>
                    <View style={styles.tableBody}>
                        { !data
                        ? <View style={styles.tableRow}>
                            <Text style={styles.tableCell}>Nenhum dado encontrado.</Text>
                        </View>
                        :data.map((item, index) => (
                            <View key={index} style={styles.tableRow}>
                                <Text style={styles.tableCell}>{item.indicator.name}</Text>
                                <Text style={styles.tableCell}>{item.building.name}</Text>
                                <Text style={styles.tableCell}>{toMonthYearString(item.date)}</Text>
                                <Text style={styles.tableCell}>{item.value ?? '---'}</Text>
                                <Text style={styles.tableCell}>{item.description}</Text>
                            </View>
                        ))}
                    </View>
                </View>
            </View>
        </Page>
    </Document>
}

type useIndicatorPDFReportProps = {
  data?: IndicatorRecordType[], 
  fileName: string
}

export function useIndicatorPDFReport({data, fileName}: useIndicatorPDFReportProps){
  // const [instance, updateInstance] = usePDF({document: <IndicatorPDFReport data={data} />})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>()

  async function download(){
    try{
      setLoading(true)
      const blob = await pdf(<IndicatorPDFReport data={data} />).toBlob()
      const name = `${fileName}.pdf`
      await downloadFile(blob, name)
    }catch(error){
      setError((error as Error).message)
    }
    setLoading(false)
  }
  return {
    loading,
    error,
    download
  }
}