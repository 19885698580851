import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider } from 'react-router-dom';
import { router } from 'routes/router';
import { AuthProvider } from 'services/auth.service';
import { ColorThemeProvider } from 'services/theme.service';

import { LocalizationProvider } from '@mui/x-date-pickers';
// If you are using date-fns v3.x, please import the v3 adapter
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { ptBR } from 'date-fns/locale';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
console.log(`env`,process.env.REACT_APP_API_HOST)

root.render(
  // <React.StrictMode>
  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
    <ColorThemeProvider >
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </ColorThemeProvider>
  </LocalizationProvider>
  // </React.StrictMode>
);
