import { Page } from "components/page/page.component";
import style from './users.module.css'
import { TextInput } from "components/textInput/textInput.component";
import { View } from "components/view/view.component";
import { InputAdornment } from "@mui/material";
import { Icon } from "components/icon/icon.component";
import { Button } from "components/button/button.component";
import { Text } from 'components/text/text.component'
import { Skeleton } from "components/skeleton"
import {Table, TableHead, TableBody, TableRow, TableCell } from 'components/table/table.component'
import { useEffect, useState } from "react";
import { useGetUsers } from "api/user.api";
import { ErrorComponent } from "components/errorComponent/error.component";
import { roles } from "data/userRoles.data";
import { removeAccentsAndSpecialChars } from "utils/stringManage.util";
import { SendResetEmail } from "services/firebase.service";
import { FEATURE } from "data/features.data";
import { host } from "config/host.config";

type UsersPageProps = {
    companyId?: string
}

export function UsersPage({companyId}: UsersPageProps){
    const [search, setSearch] = useState('')
    const {data: users, loading, error, get } = useGetUsers()

    const filteredUsers = search ? users?.filter(_ => 
        removeAccentsAndSpecialChars(_.name?.toLowerCase() ?? '').includes(removeAccentsAndSpecialChars(search.toLowerCase()))
        || removeAccentsAndSpecialChars(_.username?.toLowerCase() ?? '').includes(removeAccentsAndSpecialChars(search.toLowerCase()))
        || _.buildings?.find(building => removeAccentsAndSpecialChars(building.name?.toLowerCase() ?? '').includes(removeAccentsAndSpecialChars(search.toLowerCase())))
        || _.companies?.find(company => removeAccentsAndSpecialChars(company.name?.toLowerCase() ?? '').includes(removeAccentsAndSpecialChars(search.toLowerCase())))
        || _.roles?.find(role => removeAccentsAndSpecialChars(role.name?.toLowerCase() ?? '').includes(removeAccentsAndSpecialChars(search.toLowerCase())))
    ) : users

    useEffect(()=>{ if(companyId) get(companyId).catch(error => {}) }
    // eslint-disable-next-line
    , [])

    function handleSearchChange(text: string){
        setSearch(text)
    }

    function handleKeyClick(email: string){
        SendResetEmail(email)
        .then(()=>{
            alert('Email enviado com sucesso! Siga as instruções para redefinir senha')
        })
        .catch(error => {
            alert('Erro ao enviar email para definição de senha')
        })
    }

    if(error) return <Page menu header title="Usuários"><ErrorComponent /></Page>
    return <Page menu header title="Usuários">
        <View className={style.Content}>
            <View className={style.ContentHeader}>
                <TextInput 
                    fullWidth 
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><Icon>search</Icon></InputAdornment>,
                      }}
                    value={search}
                    onChange={(e)=> handleSearchChange(e.target.value)}
                    size='small'
                />
                <View features={[FEATURE.USER_CREATE.id]}>
                    <a href="/usuario">
                        <Button className={style.AddButton} variant='contained' size='medium' ><Icon primaryContrast >add_circle</Icon>Novo Usuário</Button>
                    </a>
                </View>
            </View>
        {loading
        ?  <Skeleton className={style.Skeleton} />
        : <View className={style.TableContainer}>
        <View className={style.TableContent}>
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell><Text type='h4'>Email/Login</Text></TableCell>
                            <TableCell><Text type='h4'>Nome</Text></TableCell>
                            <TableCell><Text type='h4'>Perfil</Text></TableCell>
                            <TableCell><Text type='h4'>Empresa</Text></TableCell>
                            <TableCell><Text type='h4'>Prédios</Text></TableCell>
                            <TableCell><Text type='h4'>Ativo</Text></TableCell>
                            <TableCell><Text type='h4'>Ações</Text></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                        (!filteredUsers || filteredUsers?.length <= 0)
                        ? <TableRow className={style.Content}>
                            <TableCell colSpan={7}>
                                <Text type='sm'>Nenhum Usuário encontrado</Text>
                            </TableCell>
                        </TableRow>
                        : filteredUsers.map(user => <TableRow key={user.username}>
                            <TableCell><Text type='sm'>{user.username}</Text></TableCell>
                            <TableCell><Text type='sm'>{user.name}</Text></TableCell>
                            <TableCell><Text type='sm'>{user.roles?.map(role => role.name ?? roles.find(_ => _.id === role.id)?.name).join(',') ?? ''}</Text></TableCell>
                            <TableCell><Text type='sm'>{user.companies?.map(_ => _.name).join(', ') ?? ''}</Text></TableCell>
                            <TableCell><Text type='sm'>{user.buildings?.map(_ => _.name).join(', ') ?? ''}</Text></TableCell>
                            <TableCell><Text type='sm'>{!user.disable ? 'Ativo' : 'Inativo'}</Text></TableCell>
                            <TableCell>
                                <View className={ style.TableActionsIcons} >
                                    <View features={[FEATURE.USER_UPDATE.id]} title="Editar usuário">
                                    <a href={`/usuario/${user.id}`}>
                                        <Icon >edit_square</Icon>
                                    </a>
                                    </View>
                                    <View title="Enviar email de recuperação de senha">
                                        <Icon onClick={() => handleKeyClick(user.username ?? '')}>key</Icon>
                                    </View>
                                </View>
                            </TableCell>
                        </TableRow>)
                        }
                    </TableBody>
                </Table>
            </View>
        </View>
        }
        </View>
    </Page>
}