import axios from "axios";
import { useState } from "react";
import { getAuthToken, useAuth } from "./auth.service";

type HeadersType = {
    Authorization?: string
}

export function useHTTPClient<T>(url: string){
    // const [url, setUrl] = useState(initialUrl);
    const [data, setData] = useState<T>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const authToken = getAuthToken()
    const {showNeedAuth} = useAuth()

    const get = async ({header, _url}: {header?: any, _url?: string}): Promise<T | undefined> =>{
        setLoading(true)
        setError(null)
        const headers: HeadersType = {}
        if(authToken) headers.Authorization = authToken
        return await axios.get(_url ?? url  , {
            timeout: 50000, // Set a timeout of 5 seconds
            headers: headers
          }
        ).then(response => {
            setData(response.data)
            setError(null)
            return response.data
        })
        .catch(error =>{
            if(error.response.status === 401 && showNeedAuth) showNeedAuth()
            setData(undefined)
            setError(error)
            throw error
        })
        .finally(() => {
            setLoading(false)
        })
    }

    const post = async ({data, header, _url}: {data?: any, header?: any, _url?: string}): Promise<any> =>{
        setLoading(true)
        setError(null)
        const headers: HeadersType = {}
        if(authToken) headers.Authorization = authToken
        return await axios.post(_url ?? url  , data, {
            timeout: 50000, // Set a timeout of 5 seconds
            headers: headers
          }
        ).then(response => {
            setData(response.data)
            setError(null)
            return response.data
        })
        .catch(error =>{
            setData(undefined)
            setError(error)
            throw error
        })
        .finally(() => {
            setLoading(false)
        })
    }

    const patch = async ({data, header, _url}: {data?: any, header?: any, _url?: string}): Promise<any> =>{
        setLoading(true)
        setError(null)
        const headers: HeadersType = {}
        if(authToken) headers.Authorization = authToken
        return await axios.patch(_url ?? url , data, {
            timeout: 50000, // Set a timeout of 5 seconds
            headers: headers
          }
        ).then(response => {
            setData(response.data)
            setError(null)
            return response.data
        })
        .catch(error =>{
            setData(undefined)
            setError(error)
            throw error
        })
        .finally(() => {
            setLoading(false)
        })
    }

    const put = async ({data, header, _url}: {data?: any, header?: any, _url?: string}): Promise<any> =>{
        setLoading(true)
        setError(null)
        const headers: HeadersType = {}
        if(authToken) headers.Authorization = authToken
        return await axios.put( _url ?? url , data, {
            timeout: 50000, // Set a timeout of 5 seconds
            headers: headers
          }
        ).then(response => {
            setData(response.data)
            setError(null)
            return response.data
        })
        .catch(error =>{
            setData(undefined)
            setError(error)
            throw error
        })
        .finally(() => {
            setLoading(false)
        })
    }

    const _delete = async ({header, _url}: {header?: any, _url?: string}): Promise<T | undefined> =>{
        setLoading(true)
        setError(null)
        const headers: HeadersType = {}
        if(authToken) headers.Authorization = authToken
        return await axios.delete(_url ?? url  , {
            timeout:50000, // Set a timeout of 5 seconds
            headers: headers
          }
        ).then(response => {
            setData(response.data)
            setError(null)
            return response.data
        })
        .catch(error =>{
            setData(undefined)
            setError(error)
            throw error
        })
        .finally(() => {
            setLoading(false)
        })
    }

    return {
        data,
        loading,
        error,
        get,
        post,
        put,
        patch,
        _delete
    }
}