import { Page } from "components/page/page.component"
import { View } from "components/view/view.component"
import { Text } from 'components/text/text.component'
import style from './reports.module.css'
import { Skeleton } from "components/skeleton"
import { Table, TableBody, TableCell, TableHead, TableRow } from "components/table/table.component"
import { useEffect, useState } from "react"
import { useGetIndicatorRecordsApi } from "api/indicatorRecord.api"
import { toMonthYearString } from "utils/date.util"
import { Button } from "components/button/button.component"
import { Icon } from "components/icon/icon.component"
import { useIndicatorPDFReport } from "reports/indicator.pdf.report"
import { IndicadorExcelReport } from "reports/indicator.excel.report"
import { textWithLineBreaks } from "utils/stringManage.util"
import { Filters } from "components/filters/filters.component"
import { FilterType } from "model/filter.model"

type ReportsPageProps = {
    companyId?: string
}
export function ReportsPage({companyId}: ReportsPageProps){
    const [filter, setFilter] = useState<FilterType | undefined>()

    const {loading: indicatorRecordsLoading, get: getIndicatorRecordsApi, data: indicatorRecords } = useGetIndicatorRecordsApi()
    
    const indicatorRecordsSorted = indicatorRecords?.sort((a,b) => {
        const nameComparison = a.building.name?.localeCompare(b.building.name ?? '')
                            
        if(nameComparison && nameComparison !== 0) return nameComparison
        
        return (new Date(b.date).getTime()) - (new Date(a.date).getTime())
    })

    const {loading: loadingExcelReport, download: downloadExcelReport} = IndicadorExcelReport({data: indicatorRecordsSorted, fileName: 'AROTech - Relatorio de indicadores'})
    const {loading: loadingPDFReport, download: downloadPDFReport} = useIndicatorPDFReport({data: indicatorRecordsSorted, fileName: 'AROTech - Relatorio de indicadores'} )
    
    function handleOnFilterChange(filter: FilterType){
        setFilter(filter)
    }

    useEffect(()=>{
        if(!companyId) return
        if(!filter?.buildings || filter.buildings.length <= 0) return
        if(!filter?.indicators || filter.indicators.length <= 0) return
        const _filter = {
            buildings: filter.buildings.map(_ => _.id),
            indicators: filter.indicators.map(_ => _.id),
            companyId: companyId,
            startDate: filter.startDate,
            endDate: filter.endDate
        }
        getIndicatorRecordsApi(_filter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filter, companyId])
    
    return <Page menu header title="Relatório">
        <View className={style.Content}>
            
        <View className={style.ContentHeader}>
            <Filters companyId={companyId} showIndicators indicatorsFor='report' showBuildingGroups showBuildings showStartDate showEndDate onChange={handleOnFilterChange}/>
        </View>
        <View className={style.ExportSection}>
            <View className={style.ExportSectionButtons}>
                <Button variant='contained' startIcon={<Icon className={style.Icons}>picture_as_pdf</Icon>}
                    onClick={downloadPDFReport}
                    disabled={!indicatorRecordsSorted || indicatorRecordsSorted.length <= 0}
                    loading={loadingPDFReport}
                >
                    Exportar para PDF
                </Button>
                <Button 
                    variant='contained' 
                    startIcon={<Icon className={style.Icons}>border_all</Icon>}
                    loading={loadingExcelReport}
                    onClick={downloadExcelReport}
                    disabled={!indicatorRecordsSorted || indicatorRecordsSorted.length <= 0}
                >
                    Exportar para Excel
                </Button>
            </View>
        </View>
    {indicatorRecordsLoading
    ? <Skeleton className={style.TableContainer} />
    :<View className={style.TableContainer}>
        <View className={style.TableContent}>
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell><Text type='h4'>Indicador</Text></TableCell>
                            <TableCell><Text type='h4'>Prédio</Text></TableCell>
                            <TableCell><Text type='h4'>Mês/Ano</Text></TableCell>
                            <TableCell><Text type='h4'>Valor</Text></TableCell>
                            <TableCell><Text type='h4'>Observação</Text></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                        (!filter?.indicators || filter?.indicators.length <= 0)
                        ?<TableRow className={style.Content}>
                            <TableCell colSpan={5}>
                                <Text type='sm'>Selecione um indicador</Text>
                            </TableCell>
                        </TableRow>
                        : (!filter.buildings || filter.buildings.length <= 0)
                        ?<TableRow className={style.Content}>
                            <TableCell colSpan={5}>
                                <Text type='sm'>Selecione um prédio</Text>
                            </TableCell>
                        </TableRow>
                        : (!indicatorRecordsSorted || indicatorRecordsSorted?.length <= 0)
                        ? <TableRow className={style.Content}>
                            <TableCell colSpan={5}>
                                <Text type='sm'>Nenhum Lançamento encontrado</Text>
                            </TableCell>
                        </TableRow>
                        : indicatorRecordsSorted.map((indicatorRecord, index) => <TableRow key={index}>
                            <TableCell><Text type='sm'>{ indicatorRecord.indicator.name ?? '---' }</Text></TableCell>
                            <TableCell><Text type='sm'>{ indicatorRecord.building.name ?? '---' }</Text></TableCell>
                            <TableCell><Text type='sm'>{toMonthYearString(indicatorRecord.date) ?? '---'}</Text></TableCell>
                            <TableCell>
                                <Text type='sm'>{indicatorRecord.value ? `${parseFloat(indicatorRecord.value.toFixed(2)).toLocaleString('pt-BR')}${indicatorRecord.indicator.unit ? ` ${indicatorRecord.indicator.unit}` : ''}` : '---'}</Text>
                            </TableCell>
                            <TableCell>{ textWithLineBreaks(indicatorRecord.description ?? '---') }</TableCell>
                        </TableRow>)
                        }
                    </TableBody>
                </Table>
        </View>
    </View>
    }
    </View>
    </Page>
}