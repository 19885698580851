import { Page } from "components/page/page.component";
import { View } from "components/view/view.component";
import { Text } from 'components/text/text.component'
import style from './user.page.module.css'
import { Switch } from "components/switch/switch.component";
import { Button } from "components/button/button.component";
import { Skeleton } from "components/skeleton";
import { TextInput } from "components/textInput/textInput.component";
import { useEffect, useState } from "react";
import { useGetUser, useSaveNewUser, useUpdateUser } from "api/user.api";
import { useNavigation } from "services/navigation.service";
import { Select, SelectItem } from "components/select/select.component";
import { ROLE, roles } from "data/userRoles.data";
import { useGetBuildingsApi } from "api/building.api";
import { ErrorComponent } from "components/errorComponent/error.component";
import { useGetCompaniesApi } from "api/company.api";
import { User } from "model/user.model";
import z from "zod"

type UserPageProps = {
    userId?: string
}

export function UserPage({userId}:UserPageProps) {
    const [active, setActive] = useState(true)
    const [name, setName] = useState('')
    const [username, setUsername] = useState('')
    const [role, setRole] = useState('')
    const [buildings, setBuildings] = useState<string[]>([])
    const [company, setCompany]= useState('')
    const [originalUser, setOriginalUser] = useState<User | undefined>()
    const {loading: loadingSaveNewUser, post: saveNew } = useSaveNewUser()
    const {loading: loadingUpdateUser, update } = useUpdateUser()
    const {loading: loadingUser, error: errorUser, get } = useGetUser(userId ?? '')
    const {data: companiesData, loading: loadingCompanies, get: getCompanies } = useGetCompaniesApi()
    const {data: buildingsData, loading: loadingBuildings, get: getBuildings} = useGetBuildingsApi('')
    const {navigate} = useNavigation()

    useEffect(()=>{
        if(userId) getUser()
        getCompanies().catch(()=>{})
    // eslint-disable-next-line
    },[])

    useEffect(()=>{
        if(!company) return
        setBuildings([])
        getBuildings(company)
        .catch(()=>{})
        if(originalUser?.companies?.find(_ => _.id === company)) setBuildings(originalUser.buildings?.map(_ => _.id) ?? [])
    // eslint-disable-next-line
    }, [company])

    async function getUser(){
        const _user = await get({})
        if(!_user) return
        setOriginalUser(_user)
        if(_user.disable !== undefined) setActive(!_user.disable)
        setName(_user.name ?? '')
        setUsername(_user.username ?? '')
        setRole(_user.roles?.at(0)?.id ?? '')
        setCompany(_user.companies?.at(0)?.id ?? '')
        getBuildings(_user.companies?.at(0)?.id)
        .then(()=>{
            setBuildings(_user.buildings?.map(_ => _.id) ?? [])
        })
        .catch(()=>{})
}

    function handleActiveChange(){
        setActive(!active)
    }

    function handleNameChange(_name: string) {
        setName(_name)
    }

    function handleUsernameChange(_username: string){
        setUsername(_username)
    }

    function handleRoleChange(_role: string){
        setRole(_role)
    }

    function handleCompanyChange(_company: string){
        setCompany(_company)
    }

    function handleBuildingsChange(_buildings: any){
        setBuildings(_buildings)
    }

    function handleGoBack(){
        navigate('/usuarios')
    }

    function handleSave(){
        if(userId) updateUser()
        else saveNewUser()
    }

    function _getUser(): User {
        const _roles = role ? [{id: role}] : []
        const _companies = company ? [{id: company}] : []
        const _buildings = buildings.map(_ => {return {id: _}})
        const _user: User = {
            name: name,
            username: username,
            roles: _roles,
            companies: _companies,
            buildings: _buildings,
            email: username,
            disable: !active
        }
        return _user
    }
    function isEmail(email: string){
        try{
            const emailSchema = z.string().email()
            emailSchema.parse(email)
            return true
        }
        catch(error) {
            return false
        }
    }
    function invalidUser(u: User){
        if(!u.username) { alert('Preencha o email'); return true }
        if(!isEmail(u.username)) { alert('Preencha um email válido'); return true }
        if(!role) { alert('Selecione um perfil'); return true }
        if( role !== ROLE.admin.id && !company) { alert('Selecione uma empresa'); return true }
        return false
    }

    function saveNewUser(){
        console.log('Salvar novo usuario...')
        const _user = _getUser()
        if(invalidUser(_user)) return
        console.log(`Usuário a ser salvo: `, _user)
        saveNew({data: _user })
        .then(()=>{
            alert('Sucesso ao salvar novo usuário')
            handleGoBack()
        })
        .catch(()=>{
            alert('Houve um erro ao salvar novo usuário')
        })
    }

    function updateUser(){
        if(!userId) return
        console.log('Salvar usuario...')
        const _user = _getUser()
        if(invalidUser(_user)) return
        console.log(`Usuário a ser salvo: `, _user)
        update(userId, _user)
        .then(()=>{
            alert('Sucesso ao salvar usuário')
            handleGoBack()
        })
        .catch(()=>{
            alert('Houve um erro ao salvar usuário')
        })
    }

    if(errorUser) return <Page menu header title="Usuário"> <ErrorComponent /> </Page>
    return <Page menu header title="Usuário">
        <View className={style.Content}>
            <View className={style.FormContainer}>
                <View className={style.Form}>
                    <View className={style.FormItemContainer}>
                        <View className={style.DisableContainer}>
                            <Text type='h4'>Ativo</Text>
                            <Switch checked={active} onChange={handleActiveChange}/>
                        </View>
                    </View>

                    <View className={style.FormItemContainer}>
                        <Text type='h4'>Email</Text>
                        <View className={style.UsernameContainer}>
                            {loadingUser 
                            ? <Skeleton color='bg3'/>
                            : <TextInput type='email' disabled={!!userId || loadingSaveNewUser || loadingUpdateUser} size='small' fullWidth value={username} onChange={e => handleUsernameChange(e.target.value)} />
                            }
                        </View>
                    </View>

                    <View className={style.FormItemContainer}>
                        <Text type='h4'>Nome</Text>
                        {loadingUser 
                        ? <Skeleton color='bg3'/>
                        : <TextInput disabled={loadingSaveNewUser || loadingUpdateUser} size='small' fullWidth value={name} onChange={e => handleNameChange(e.target.value)}/>}
                    </View>
                    
                    <View className={style.FormItemContainer}>
                        <Text type='h4'>Perfil</Text>
                        <Select size='small' value={role} onChange={e => handleRoleChange(e.target.value)}>
                            {roles.map((role, index) => <SelectItem key={index} value={role.id}>{role.name}</SelectItem>)}
                            
                        </Select>
                    </View>

                    <View className={style.FormItemContainer}>
                        <Text type='h4'>Empresa</Text>
                        {
                            loadingCompanies
                            ? <Skeleton color='bg3'/>
                            : <Select size='small' value={company} onChange={e => handleCompanyChange(e.target.value)}>
                                {companiesData?.map((b, index) => <SelectItem key={index} value={b.username}>{b.name}</SelectItem> )}
                            </Select>
                        
                        }
                    </View>

                    <View className={style.FormItemContainer}>
                        <Text type='h4'>Prédios</Text>
                        {
                            loadingBuildings
                            ? <Skeleton color='bg3'/>
                            : <Select multiple size='small' value={buildings} onChange={e => handleBuildingsChange(e.target.value)}>
                                {buildingsData?.map((b, index) => <SelectItem key={index} value={b.id}>{b.name}</SelectItem> )}
                            </Select>
                        
                        }
                    </View>
                </View>
            </View>
            <View className={style.Buttons}>
                <Button onClick={handleGoBack}>Voltar</Button>
                <Button disabled={loadingUser} loading={loadingSaveNewUser || loadingUpdateUser} variant='contained' onClick={handleSave}>Salvar</Button>
            </View>
        </View>
    </Page>
}