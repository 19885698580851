export function generateRandomHexColor() {
    // Gerar um número hexadecimal aleatório entre 0 e 16777215 (equivalente a FFFFFF em hexadecimal)
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    
    // Garantir que o número tenha 6 dígitos, preenchendo com zeros à esquerda, se necessário
    const hexColor = '#' + '0'.repeat(6 - randomColor.length) + randomColor;
  
    return hexColor;
  }

export function colorWithTransparency(hexColor: string, transparency: number): string {
    // Remover o símbolo '#' se estiver presente
    if (hexColor.startsWith('#')) {
        hexColor = hexColor.slice(1);
    }

    // Verificar se a cor hexadecimal tem 6 caracteres
    if (hexColor.length !== 6) {
        throw new Error("Invalid hex color format. Expected format is RRGGBB.");
    }

    // Converter a transparência para um valor entre 00 e FF
    const alpha = Math.round(transparency * 255);
    const alphaHex = alpha.toString(16).padStart(2, '0').toUpperCase();

    // Retornar a cor hexadecimal com a transparência
    return `#${hexColor}${alphaHex}`;
}