import { Page } from "components/page/page.component"
import style from './signin.module.css'
import { useAuth } from "services/auth.service"
import { Navigate, useNavigation } from "services/navigation.service"
import { View } from "components/view/view.component"
import logo from 'assets/images/ARO Tech - logo vetor.png'
import { Text } from "components/text/text.component"
import { TextInput } from "components/textInput/textInput.component"
import { Button } from "components/button/button.component"
import { useEffect, useState } from "react"

export function SigninPage(){
    const { token, login, loading, error: loginError, resetError } = useAuth()
    const { search, navigate } = useNavigation()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState()

    useEffect(()=>{
        setError(loginError)
    }, [loginError])

    async function handleLogin() {
        try {
            await login(username, password);
            resetError()
        } catch (error: any) {
            setError(error)
        }
    }

    function handleForgotPassword(){
        const email = username && `?email=${username}`
        navigate(`/forgot-password${email}`)
    }

    function handleUsernameChange(newUsername: string){
        setError(undefined)
        setUsername(newUsername)
    }

    function handlePasswordChange(newPassword: string){
        setError(undefined)
        setPassword(newPassword)
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
      };

    const to = search.from ? search.from : '/'
    if(token) return <Navigate to={to as string} replace />

    return <Page className={style.Page}>
        <View className={style.Box}>
            <View className={style.Content}>
                <View className={style.ContentHeader}>
                    <img className={style.LogoImage} src={logo} alt="Logo ARO Tech" />
                </View>
                <View className={style.ContentBody}>
                    <View className={style.ContentBodySection}>
                        <Text type="h1" weight="medium">Fazer Login</Text>
                        <Text className={style.Subtitle} type='lg'>Todas as informações de que você precisa para uma boa gestão de recursos.</Text>
                    </View>
                    <View className={style.ContentBodySection}>
                        {error && <View className={style.ContentContainer}>
                            <Text className={style.ErrorMessage} type='h4' weight='medium'>{(error as Error)?.message ?? 'Erro ao realizar login.'}</Text>
                        </View>}
                        <View className={style.ContentContainer}>
                            <Text type='h3' weight='medium'>Nome de Usuário</Text>
                            <TextInput disabled={loading} fullWidth placeholder="admin@arotech.app" value={username} onChange={e => handleUsernameChange(e.target.value)} onKeyDown={handleKeyDown}/>
                        </View>
                        <View className={style.ContentContainer}>
                            <Text type='h3' weight='medium'>Senha</Text>
                            <TextInput disabled={loading} fullWidth placeholder="digite aqui sua senha" type='password' value={password} onChange={e => handlePasswordChange(e.target.value)} onKeyDown={handleKeyDown}/>
                            <Text type='h4' weight='semiBold' className={style.ForgotPasswordLink} onClick={handleForgotPassword}> Esqueci minha senha</Text>
                        </View>
                        <Button id={style.LoginButton} variant='contained' onClick={handleLogin} loading={loading}> Entrar </Button>
                    </View>
                </View>
            </View>
        </View>
    </Page>
}