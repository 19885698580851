import { Page } from "components/page/page.component";
import { Table, TableBody, TableCell, TableHead, TableRow } from "components/table/table.component";
import { View } from "components/view/view.component";
import { Text } from 'components/text/text.component'
import style from './pendingRecords.page.module.css'
import { toMonthYearString } from "utils/date.util";
import { Skeleton } from "components/skeleton";
import { useEffect, useState } from "react";
import { FEATURE } from "data/features.data";
import { Icon } from "components/icon/icon.component";
import { useGetPendingRecordsApi } from "api/indicatorRecord.api";
import { Filters } from "components/filters/filters.component";
import { FilterType } from "model/filter.model";

type PendingRecordsPageProps = {
    companyId: string
}

export function PendingRecordsPage({companyId}: PendingRecordsPageProps){
    // const [building, setBuilding] = useState('')
    const [filter, setFilter] = useState<FilterType | undefined>()
    // const [startDate, setStartDate] = useState<Date | undefined>( getOneYearBefore( getOneMonthBefore(new Date() )))
    // const [endDate, setEndDate] = useState<Date | undefined>( getOneMonthBefore(new Date()))
    // const {loading: buildingsLoading, get: getBuildings, data: buildings} = useGetBuildingsApi(companyId)
    const {loading, get: getPendingRecordsApi, data: pendingRecords} = useGetPendingRecordsApi()
    // const [pendingRecords, setPendingRecords] = useState<PendingRecordType[] | undefined>()

    // useEffect(()=>{
    //     getBuildings().catch(()=>{})
    // // eslint-disable-next-line
    // },[])

    // useEffect(()=>{
    //     getPendingRecords()
    // // eslint-disable-next-line
    // },[building, startDate, endDate])

    useEffect(()=>{
        getPendingRecords()
    // eslint-disable-next-line
    },[filter])

    // function handleBuildingChange(newBuilding: string){
    //     setBuilding(newBuilding)
    // }

    function handleOnFilterChange(filter: FilterType){
        const _filter = {...filter, companyId: companyId}
        setFilter(_filter)
    }

    function getPendingRecords(){
        if(!filter || !filter.startDate || !filter.endDate) return
        const _filter: {buildings?: string[], companyId: string, startDate?: Date, endDate?: Date} = {companyId: companyId}
        if(filter.buildings) _filter.buildings = typeof filter.buildings === 'string' ? [filter.buildings] : filter.buildings.map(_ => _.id)
        _filter.startDate = filter.startDate
        _filter.endDate = filter.endDate
        console.log(`filters`, _filter)
        getPendingRecordsApi(_filter).catch(()=>{})
    }

    // function _getPendingRecords(){
    //     if(!building || !startDate || !endDate) return
    //     const filter: {buildings: string[], companyId: string, startDate?: Date, endDate?: Date} = {buildings: [building], companyId: companyId}
    //     filter.startDate = startDate
    //     filter.endDate = endDate
    //     getPendingRecordsApi(filter).catch(()=>{})
    // }

    return <Page menu header title='Pendência de lançamentos'>
        <View className={style.Content}>
            <Filters companyId={companyId} showBuildings showBuildingGroups showStartDate showEndDate onChange={handleOnFilterChange} />
            {loading ?
            <Skeleton className={style.TableContainer} />
            :
            <View className={style.TableContainer}>
                <View className={style.TableContent}>
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell><Text type='h4'>Indicador</Text></TableCell>
                                <TableCell><Text type='h4'>Mês</Text></TableCell>
                                <TableCell><Text type='h4'>Prédio</Text></TableCell>
                                <TableCell><Text type='h4'>Ações</Text></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                            // !filter?.buildings || filter.buildings.length <= 0
                            // ?<TableRow className={style.Content}>
                            //     <TableCell colSpan={5}>
                            //         <Text type='sm'>Selecione um prédio</Text>
                            //     </TableCell>
                            // </TableRow>
                            // : 
                            (!pendingRecords || pendingRecords?.length <= 0)
                            ? <TableRow className={style.Content}>
                                <TableCell colSpan={5}>
                                    <Text type='sm'>Nenhuma pendência de lançamentos</Text>
                                </TableCell>
                            </TableRow>
                            : pendingRecords.map((pendingRecord, index) => { 
                                return <TableRow key={index}>
                                <TableCell>
                                    <Text type='sm'>{pendingRecord.indicator.name}</Text>
                                </TableCell>
                                <TableCell><Text type='sm'>{toMonthYearString(pendingRecord.date)}</Text></TableCell>
                                
                                <TableCell><Text type='sm'>{ pendingRecord.building.name }</Text></TableCell>
                                <TableCell >
                                    <View className={style.TableCellActions} >
                                        <View title='Editar lançamento' features={[FEATURE.INDICATOR_RECORD_UPDATE.id]}>
                                        <a href={`/${companyId}/lancamento-indicador/${pendingRecord.indicator.id}?data=${new Date(pendingRecord.date).toUTCString()}&predio=${pendingRecord.building.id}`}>
                                            <Icon >edit_square</Icon>
                                        </a>
                                        </View>
                                    </View>
                                </TableCell>
                            </TableRow>})
                            }
                        </TableBody>
                    </Table>
                </View>
            </View>
            }
        </View>
    </Page>
}