import style from './indicators.module.css'
import { Page } from "components/page/page.component";
import { View } from "components/view/view.component";
import { Text } from 'components/text/text.component'
import { Icon } from 'components/icon/icon.component';
import { Button } from 'components/button/button.component';
import { Skeleton } from 'components/skeleton';
import { Table, TableHead, TableBody, TableCell, TableRow  } from 'components/table/table.component'
import { useEffect, useState } from 'react';
import { Select, SelectItem } from 'components/select/select.component'
import { useGetBuildingsApi } from 'api/building.api';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useGetIndicatorApi } from 'api/indicator.api';
import { FEATURE } from 'data/features.data';
import { useDeleteIndicatorRecordApi, useGetIndicatorRecordsApi } from 'api/indicatorRecord.api';
import { getOneMonthBefore, getOneYearBefore, toMonthYearString } from 'utils/date.util';
import { Dialog } from 'components/dialog/dialog.component';
import { host } from 'config/host.config';


type IndicatorsPageProps = {
    companyId: string,
    indicatorId: string
}
export function IndicatorsPage({companyId, indicatorId}: IndicatorsPageProps){
    const [building, setBuilding] = useState('')
    const [startDate, setStartDate] = useState<Date | undefined>( getOneYearBefore( getOneMonthBefore(new Date() )))
    const [endDate, setEndDate] = useState<Date | undefined>( getOneMonthBefore(new Date()))
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
    const [indicatorRecordSelected, setIndicatorRecordSelected] = useState<string | undefined>(undefined)
    const {loading: buildingsLoading, get: getBuildings, data: buildings} = useGetBuildingsApi(companyId)
    const {get: getIndicator, data: indicator} = useGetIndicatorApi(indicatorId)
    const {loading: indicatorRecordsLoading, get: getIndicatorRecordsApi, data: indicatorRecords } = useGetIndicatorRecordsApi()
    const {loading: deleteIndicatorRecordLoading, delete: deleteIndicatorRecord } = useDeleteIndicatorRecordApi()

    useEffect(()=>{
        getIndicator({}).catch(()=>{})
        getBuildings().catch(()=>{})
    // eslint-disable-next-line
    },[])

    useEffect(()=>{
        getIndicatorRecords()
    // eslint-disable-next-line
    },[building, startDate, endDate])

    useEffect(() => {
        if(indicatorRecordSelected) setOpenDeleteDialog(true)
    }, [indicatorRecordSelected])

    function getIndicatorRecords(){
        if(!building || !startDate || !endDate) return
        const filter: {buildings: string[], indicators: string[], companyId: string, startDate?: Date, endDate?: Date} = {buildings: [building], indicators: [indicatorId], companyId: companyId}
        filter.startDate = startDate
        filter.endDate = endDate
        getIndicatorRecordsApi(filter).catch(()=>{})
    }

    function handleBuildingChange(newBuilding: string){
        setBuilding(newBuilding)
    }

    function handleDeleteIndicatorRecord(indicatorRecordId: string){
        setIndicatorRecordSelected(indicatorRecordId)
        setOpenDeleteDialog(true)
    }

    function handleCloseDeleteDialog(){
        setIndicatorRecordSelected(undefined)
        setOpenDeleteDialog(false)
    }

    function handleDelete(){
        if(!indicatorRecordSelected) return alert('lançamento não identificado')
        
        deleteIndicatorRecord(indicatorRecordSelected)
        .then(() => {
            alert('Lançamento deletado com sucesso')
            handleCloseDeleteDialog()
            getIndicatorRecords()
        })
        .catch((error)=>{
            alert(`Erro ao deletar lançamento. Mensagem: ${error.message}`)
        })
    }
    
    return <Page className={style.Page} menu header title={indicator ? indicator.name : 'Indicador'}>
    <View className={style.Content}>
        <View className={style.ContentHeader}>
            <View className={style.ContentHeaderSection}>
                <View className={style.ContentHeaderSectionItem} style={{width:'100%'}}>
                    <Text type='h4'>Prédio</Text>
                    {buildingsLoading
                    ? <Skeleton color='bg3'/>
                    : <Select style={{textAlign: 'left'}} size='small' fullWidth value={building} onChange={e => handleBuildingChange(e.target.value)} >
                        {buildings?.map(_ => <SelectItem key={_.id} value={_.id}>{_.name}</SelectItem>)}
                    </Select>
                    }
                </View>
                <View className={style.ContentHeaderSectionItem} features={[FEATURE.INDICATOR_RECORD_CREATE.id]}>
                    <a href={`/${companyId}/lancamento-indicador/${indicatorId}`}>
                        <Button className={style.AddButton} variant='contained' size='medium' ><Icon primaryContrast >add_circle</Icon>Novo Lançamento</Button>
                    </a>
                </View>
            </View>
            <View className={style.ContentHeaderSection}>
                <View className={style.ContentHeaderSectionItem} style={{width:'100%'}}>
                    <Text type='h4'>Inicio:</Text>
                    <DatePicker views={['month', 'year']} sx={{width: '100%'}} value={startDate} onMonthChange={date => setStartDate(date)} onYearChange={date => setStartDate(date)}/>
                </View>
                <View className={style.ContentHeaderSectionItem} style={{width:'100%'}}>
                    <Text type='h4'>Fim:</Text>
                    <DatePicker views={['month', 'year']} sx={{width: '100%'}} value={endDate} onMonthChange={date => setEndDate(date)} onYearChange={date => setEndDate(date)}/>
                </View>
            </View>
        </View>

    {indicatorRecordsLoading
    ? <Skeleton className={style.TableContainer} />
    :<View className={style.TableContainer}>
        <View className={style.TableContent}>
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell><Text type='h4'>Mês/Ano</Text></TableCell>
                            <TableCell><Text type='h4'>{`Valor${indicator?.unit ? ` (${indicator.unit})` : ''}`}</Text></TableCell>
                            <TableCell><Text type='h4'>Observação</Text></TableCell>
                            <TableCell><Text type='h4'>Ações</Text></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                        !building
                        ?<TableRow className={style.Content}>
                            <TableCell colSpan={5}>
                                <Text type='sm'>Selecione um prédio</Text>
                            </TableCell>
                        </TableRow>
                        : (!indicatorRecords || indicatorRecords?.filter(_ => !!_.id).length <= 0)
                        ? <TableRow className={style.Content}>
                            <TableCell colSpan={5}>
                                <Text type='sm'>Nenhum Lançamento encontrado</Text>
                            </TableCell>
                        </TableRow>
                        : indicatorRecords.sort((a, b) => (new Date(b.date).getTime()) - (new Date(a.date).getTime())).map(indicatorRecord => { 
                            if(!indicatorRecord.id) return undefined
                            return <TableRow key={indicatorRecord.id}>
                            <TableCell><Text type='sm'>{toMonthYearString(indicatorRecord.date)}</Text></TableCell>
                            <TableCell>
                                <Text type='sm'>{indicatorRecord.value.toLocaleString() ?? '---'}</Text>
                            </TableCell>
                            <TableCell><Text type='sm'>{ indicatorRecord.description }</Text></TableCell>
                            <TableCell >
                                <View className={style.TableCellActions} >
                                    <View title='Editar lançamento' features={[FEATURE.INDICATOR_RECORD_UPDATE.id]}>
                                    <a href={`/${companyId}/lancamento-indicador/${indicatorId}/${indicatorRecord.id}`}>
                                        <Icon >edit_square</Icon>
                                    </a>
                                    </View>
                                    <View title='Deletar lançamento' features={[FEATURE.INDICATOR_RECORD_DELETE.id]} onClick={() => handleDeleteIndicatorRecord(indicatorRecord.id)}>
                                        <Icon >delete</Icon>
                                    </View>
                                </View>
                            </TableCell>
                        </TableRow>})
                        }
                    </TableBody>
                </Table>
        </View>
    </View>
    }
    </View>

    <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}> 
        <View className={style.DeleteDialogContent}>
            <Text type='h2'>Deletar Lançamento</Text>
            <Text type='lg'>Tem certeza que deseja deletar o lançamento?</Text>
            <View className={style.DeleteDialogActionButtons}>
                <Button onClick={handleCloseDeleteDialog} loading={deleteIndicatorRecordLoading} disabled={deleteIndicatorRecordLoading}>Cancelar</Button>
                <Button variant='contained' onClick={handleDelete} loading={deleteIndicatorRecordLoading} disabled={deleteIndicatorRecordLoading}>Deletar</Button>
            </View>
        </View>
    </Dialog>
</Page>
}