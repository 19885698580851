import { SideBar } from "components/sideBar/sideBar.component";
import style from './sideMenu.module.css'
import Logo from 'assets/images/ARO Tech - logo vetor.png'
import { MenuItem } from "./menuItem.component";
import { View } from "components/view/view.component";
import { Image } from "components/image/image.component";
import { useAuth } from "services/auth.service";
import { FEATURE } from "data/features.data";
import { useGetCompanyIndicatorsApi } from "api/company.api";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

type SideMenuProps = {
    open?: boolean
}

export function SideMenu({open}:SideMenuProps){

    const {featuresAllowed} = useAuth()
    const {companyId} = useParams()
    const {get: getIndicators, data: indicatorsApi} = useGetCompanyIndicatorsApi(companyId ?? '')

    const inputIndicators: {id: string, title: string, link: string}[] | undefined = indicatorsApi?.inputIndicators
    ? indicatorsApi.inputIndicators.map(_ => {return{id: _.id, title: _.name, link: `/${companyId}/lancamento-indicadores/${_.id}`}}).sort((a,b) => a.title.localeCompare(b.title))
    : []

    // const reportIndicators: {id: string, title: string, link: string}[] | undefined = indicatorsApi?.reportIndicators
    // ? indicatorsApi.reportIndicators.map(_ => {return{id: _.id, title: _.name, link: `/${companyId}/relatorio/${_.id}`}})
    // : []

    const graphicIndicators: {id: string, title: string, link: string}[] | undefined = indicatorsApi?.graphicIndicators
    ? indicatorsApi.graphicIndicators.map(_ => {return{id: _.id, title: _.name, link: `/${companyId}/grafico/${_.id}`}}).sort((a,b) => a.title.localeCompare(b.title))
    : []

    useEffect(() => {
        getIndicators().catch(error=>{console.log(`Indicators error:`, error.message)})
    // eslint-disable-next-line
    }, [])

    function handleItemClick(id: string){

    }

    return <SideBar open={open} >
        <View className={style.MenuContent}>
            <a href="/">
                <View className={style.Header}>
                    <Image className={style.LogoImage} src={Logo} alt="Logo Aro Tech" />
                </View>
            </a>
            <View className={style.Content}>
                {/* {menuItems.map(_ =>  <MenuItem hide={_.features ? !featuresAllowed(_.features) : false} key={_.id} id={_.id} iconName={_.icon} text={_.title} link={_.link} onClick={handleItemClick} subItems={_.subItems}/> )} */}
                {/* <MenuItem id={'home'} iconName={'home'} text={'Home'} link={companyId ? `/${companyId}` :'/'} onClick={handleItemClick} /> */}
                <MenuItem id={'dashboard'} iconName={'dashboard'} text={'Dashboard'} link={companyId ? `/${companyId}/dashboard` :'/dashboard'} onClick={handleItemClick} />
                <MenuItem hide={!featuresAllowed([FEATURE.INDICATOR_RECORD_LIST.id])} key={'charts'} id={'charts'} iconName={'bar_chart'} text={'Gráficos'} onClick={handleItemClick} subItems={graphicIndicators}/>
                <MenuItem hide={!featuresAllowed([FEATURE.INDICATOR_RECORD_LIST.id])} key={'reports'} id={'reports'} iconName={'description'} text={'Relatórios'} onClick={handleItemClick} link={companyId ? `/${companyId}/relatorio`: `/relatorio`}/>
                <MenuItem hide={!featuresAllowed([FEATURE.INDICATOR_RECORD_LIST.id])} key={'data'} id={'data'} iconName={'table'} text={'Dados Cadastrais'} onClick={handleItemClick} subItems={inputIndicators}/>
                <MenuItem hide={!featuresAllowed([FEATURE.USER_LIST.id])} key={'users'} id={'users'} iconName={'group'} text={'Usuários'} link={companyId ? `/${companyId}/usuarios` :'/usuarios'} onClick={handleItemClick} />
                <MenuItem hide={!featuresAllowed([FEATURE.BUILDING_GROUP_LIST.id]) } key={'groups'} id={'groups'} iconName={'lan'} text={'Grupos de Prédios'} link={companyId ? `/${companyId}/grupos-de-predios` :'/grupos-de-predios'} onClick={handleItemClick} />
                <MenuItem hide={!featuresAllowed([FEATURE.BUILDING_LIST.id]) } key={'buildings'} id={'buildings'} iconName={'domain'} text={'Prédios'} link={companyId ? `/${companyId}/predios` :'/predios'} onClick={handleItemClick} />
                <MenuItem hide={!featuresAllowed([FEATURE.PENDING_RECORDS_LIST.id])} key={'peding-records'} id={'pending-records'} iconName={'sync_problem'} text="Pendência de Lançamentos" link={companyId ? `/${companyId}/pendencia-lancamentos` :'/pendencia-lancamentos'} />
                <MenuItem hide={!featuresAllowed([FEATURE.LOGS_LIST.id]) } key={'logs'} id={'logs'} iconName={'lists'} text={'Logs de Indicadores'} link={companyId ? `/${companyId}/logs` :'/logs'} onClick={handleItemClick} />
                <MenuItem hide={!featuresAllowed([FEATURE.COMPANY_LIST.id]) } key={'companies'} id={'companies'} iconName={'handshake'} text={'Empresas'} link={companyId ? `/${companyId}/empresas` :'/empresas'} onClick={handleItemClick} />
            </View>
            <View className={style.Footer}>

            </View>
        </View>
    </SideBar>
}