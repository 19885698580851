import { host } from "config/api.config"
import { User } from "model/user.model"
import { useHTTPClient } from "services/httpClient.service"

const userUrl = `${host}/user`

export function useGetUsers() {
    const {data, loading, error, get } = useHTTPClient<User[]>(`${userUrl}`)
    function getUsers(companyId: string){
        return get({_url: `${userUrl}${companyId ? `?companyId=${companyId}` : ''}`})
    }
    return {
        data, 
        loading, 
        error, 
        get: getUsers
    }
}

export function useGetUser(id: string){
    const {data, loading, error, get} = useHTTPClient<User>(`${userUrl}/${id}`)
    return {
        data,
        loading,
        error,
        get
    }
}

export function useSaveNewUser() {
    const {loading, error, post } = useHTTPClient(userUrl)

    return {
        loading,
        error,
        post
    }
}

export function useUpdateUser() {
    const {loading, error, put } = useHTTPClient(userUrl)

    async function update(userId: string, user: Partial<User>){
        await put({ data: user, _url: `${userUrl}/${userId}`})
    }

    return {
        loading,
        error,
        update
    }
}