import { ROLE } from "./userRoles.data";

export const FEATURE = {

    COMPANY_LIST : {id: 'COMPANY_LIST', name: 'Listar empresas', rolesAllowed: [ROLE.admin]},
    COMPANY_READ : {id: 'COMPANY_READ', name: 'Ler empresa', rolesAllowed: [ROLE.admin]},
    COMPANY_CREATE : {id: 'COMPANY_CREATE', name: 'Adicionar empresa', rolesAllowed: [ROLE.admin]},
    COMPANY_UPDATE : {id: 'COMPANY_UPDATE', name: 'Editar empresa', rolesAllowed: [ROLE.admin]},
    COMPANY_DELETE : {id: 'COMPANY_DELETE', name: 'Desabilitar empresa', rolesAllowed: [ROLE.admin]},

    BUILDING_LIST : {id: 'BUILDING_LIST', name: 'Listar prédios', rolesAllowed: [ROLE.admin]},
    BUILDING_READ : {id: 'BUILDING_READ', name: 'Ler prédio', rolesAllowed: [ROLE.admin]},
    BUILDING_CREATE : {id: 'BUILDING_CREATE', name: 'Adicionar prédio', rolesAllowed: [ROLE.admin]},
    BUILDING_UPDATE : {id: 'BUILDING_UPDATE', name: 'Editar prédio', rolesAllowed: [ROLE.admin]},
    BUILDING_DELETE : {id: 'BUILDING_DELETE', name: 'Desabilitar prédio', rolesAllowed: [ROLE.admin]},
    BUILDING_CHOOSE_COMPANY: {id: 'BUILDING_CHOOSE_COMPANY', name: 'Escolher empresa', rolesAllowed: [ROLE.admin]},
    
    USER_LIST : {id: 'USER_LIST', name: 'Listar usuários', rolesAllowed: [ROLE.admin, ROLE.adminEmpresa]},
    USER_READ : {id: 'USER_READ', name: 'Ler usuário', rolesAllowed: [ROLE.admin]},
    USER_CREATE : {id: 'USER_CREATE', name: 'Adicionar usuário', rolesAllowed: [ROLE.admin]},
    USER_UPDATE : {id: 'USER_UPDATE', name: 'Editar usuário', rolesAllowed: [ROLE.admin]},
    USER_DELETE : {id: 'USER_DELETE', name: 'Desabilitar usuário', rolesAllowed: [ROLE.admin]},

    BUILDING_GROUP_LIST : {id: 'BUILDING_GROUP_LIST', name: 'Listar grupos de prédios', rolesAllowed: [ROLE.admin, ROLE.adminEmpresa]},
    BUILDING_GROUP_READ : {id: 'BUILDING_GROUP_READ', name: 'Ler grupo de prédios', rolesAllowed: [ROLE.admin, ROLE.adminEmpresa]},
    BUILDING_GROUP_CREATE : {id: 'BUILDING_GROUP_CREATE', name: 'Adicionar grupo de prédios', rolesAllowed: [ROLE.admin, ROLE.adminEmpresa]},
    BUILDING_GROUP_UPDATE : {id: 'BUILDING_GROUP_UPDATE', name: 'Editar grupo de prédios', rolesAllowed: [ROLE.admin, ROLE.adminEmpresa]},
    BUILDING_GROUP_DELETE : {id: 'BUILDING_GROUP_DELETE', name: 'Deletar grupo de prédios', rolesAllowed: [ROLE.admin, ROLE.adminEmpresa]},

    INDICATOR_RECORD_CREATE : {id: 'INDICATOR_RECORD_CREATE', name: 'Adicionar novo lançamento de indicador', rolesAllowed: [ROLE.admin, ROLE.adminEmpresa, ROLE.gestor, ROLE.operador]},
    INDICATOR_RECORD_UPDATE : {id: 'INDICATOR_RECORD_UPDATE', name: 'Editar lançamento de indicador', rolesAllowed: [ROLE.admin, ROLE.adminEmpresa, ROLE.gestor, ROLE.operador]},
    INDICATOR_RECORD_DELETE : {id: 'INDICATOR_RECORD_DELETE', name: 'Deletar lançamento de indicador', rolesAllowed: [ROLE.admin, ROLE.adminEmpresa, ROLE.gestor, ROLE.operador]},
    INDICATOR_RECORD_READ : {id: 'INDICATOR_RECORD_READ', name: 'Ler dados de lançamento de indicador', rolesAllowed: [ROLE.admin, ROLE.adminEmpresa, ROLE.gestor, ROLE.operador]},
    INDICATOR_RECORD_LIST : {id: 'INDICATOR_RECORD_LIST', name: 'Listar dados de lançamento de indicador', rolesAllowed: [ROLE.admin, ROLE.adminEmpresa, ROLE.gestor, ROLE.operador]},

    PENDING_RECORDS_LIST : {id: 'PENDING_RECORDS_LIST', name: 'Listar pendências de lançamentos', rolesAllowed: [ROLE.admin, ROLE.adminEmpresa, ROLE.gestor]},
    LOGS_LIST : {id: 'LOGS_LIST', name: 'Listar logs', rolesAllowed: [ROLE.admin]},
}

export const Features = Object.values(FEATURE).map(prop => prop);