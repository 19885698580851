import { CSSProperties, ReactNode, forwardRef } from 'react'
import _style from './view.component.module.css'
import { useAuth } from 'services/auth.service'

type ViewProps = {
    children?: ReactNode,
    className?: string,
    style?: CSSProperties,
    onClick?: () => void,
    features?: string[],
    hide?: boolean,
    title?: string,
    key?: string | number
}

const View = forwardRef<HTMLDivElement, ViewProps>(({children, className, style, onClick, features, hide, title, key}: ViewProps, ref) => {
    
    const {featuresAllowed} = useAuth()

    return <div className={`${className} ${(!featuresAllowed(features) || hide) ? _style.Hide : ''} ${onClick ? _style.Clickable : ''}`} 
        style={style} 
        onClick={onClick} 
        ref={ref}
        title={title}
        key={key}
        >
        {children}
    </div>
})

export { View }