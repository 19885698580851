// import { Skeleton } from "@mui/material";
import React from 'react';
import style from './skeleton.module.css'; // Arquivo CSS com as cores definidas

type SkeletonProps = {
    className?: string,
    color?: 'bg1' | 'bg2' | 'bg3'
}
const Skeleton = ({className, color}: SkeletonProps) => {
  return (
    <div className={`${style.SkeletonContainer}`}>
      <div className={`${style.SkeletonAnimation} ${className? className : ''} ${color ? style[color] : ''}`}></div>
    </div>
  );
};


export { Skeleton }