import { InputAdornment} from '@mui/material';
import style from './buildings.module.css'
import { Page } from "components/page/page.component";
import { TextInput } from 'components/textInput/textInput.component';
import { View } from "components/view/view.component";
import { Text } from 'components/text/text.component'
import { Icon } from 'components/icon/icon.component';
import { Button } from 'components/button/button.component';
import { Skeleton } from 'components/skeleton';
import { Table, TableHead, TableBody, TableCell, TableRow  } from 'components/table/table.component'
import { useEffect, useState } from 'react';
import { Select, SelectItem } from 'components/select/select.component'
import { useGetCompaniesApi } from 'api/company.api';
import { useGetBuildingsApi } from 'api/building.api';
import { removeAccentsAndSpecialChars } from 'utils/stringManage.util';
import { ErrorComponent } from 'components/errorComponent/error.component';
import { FEATURE } from 'data/features.data';

export function BuildingsPage(){

    const [search, setSearch] = useState('')

    const [company, setCompany] = useState<string>('')
    const {data: companies, loading: companiesLoading, get: getCompaniesApi, error: errorGetCompanies} = useGetCompaniesApi()
    const {data: _buildings, loading, get: getBuildingsApi, error} = useGetBuildingsApi(company ?? '')

    useEffect(()=>{
        getCompanies()
    // eslint-disable-next-line
    },[])

    useEffect(()=>{
        getBuildings()
    // eslint-disable-next-line
    }, [company])

    function getCompanies(){
        getCompaniesApi().catch(()=>{})
    }

    function getBuildings(){
        getBuildingsApi().catch(e => console.log(e.message))
    }

    const buildings = search ? _buildings?.filter(_ => removeAccentsAndSpecialChars(_.name.toLowerCase()).includes(removeAccentsAndSpecialChars(search.toLowerCase())) ) : _buildings

    function handleCompanyChange(companyUsername: string){
        setCompany(companyUsername)
    }

    function handleSearchChange(text: string){
        setSearch(text)
    }

    function handleAtualizar(){
        getCompanies()
        getBuildings()
    }
    
    return <Page className={style.Page} menu header title="Prédios">
    <View className={style.Content}>
        <View className={style.CompanyContainer} features={[FEATURE.BUILDING_CHOOSE_COMPANY.id]}>
        <Text type='h4'>Empresa</Text>
        {companiesLoading
        ? <Skeleton color='bg3'/>
        : <Select style={{textAlign: 'left'}} size='small' fullWidth value={company} onChange={e => handleCompanyChange(e.target.value)} >
            {companies?.map(_ => <SelectItem key={_.id} value={_.username}>{_.name}</SelectItem>)}
        </Select>
        }
        </View>
        <View className={style.ContentHeader}>
            
            <TextInput 
                fullWidth 
                InputProps={{
                    startAdornment: <InputAdornment position="start"><Icon>search</Icon></InputAdornment>,
                  }}
                value={search}
                onChange={(e)=> handleSearchChange(e.target.value)}
                size='small'
            />
            <a href="/predio">
                <Button className={style.AddButton} variant='contained' size='medium' ><Icon primaryContrast >add_circle</Icon>Novo Prédio</Button>
            </a>
        </View>
    {loading
    ?  <Skeleton className={style.Skeleton} />
    : (( company && error ) || errorGetCompanies) ? <ErrorComponent >
        <Button onClick={handleAtualizar} variant='contained' >Atualizar</Button>
    </ErrorComponent>
    : <View className={style.TableContainer}>
    <View className={style.TableContent}>
            <Table >
                <TableHead>
                    <TableRow>
                        <TableCell><Text type='h4'>Nome</Text></TableCell>
                        <TableCell><Text type='h4'>Quantidade de elevadores</Text></TableCell>
                        <TableCell><Text type='h4'>Cor</Text></TableCell>
                        <TableCell><Text type='h4'>Ativo</Text></TableCell>
                        <TableCell><Text type='h4'>Ações</Text></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                    !company
                    ?<TableRow className={style.Content}>
                        <TableCell colSpan={5}>
                            <Text type='sm'>Selecione uma empresa</Text>
                        </TableCell>
                    </TableRow>
                    : (!buildings || buildings?.length <= 0)
                    ? <TableRow className={style.Content}>
                        <TableCell colSpan={5}>
                            <Text type='sm'>Nenhum Prédio encontrado</Text>
                        </TableCell>
                    </TableRow>
                    : buildings.map(building => <TableRow key={building.id}>
                        <TableCell><Text type='sm'>{building.name}</Text></TableCell>
                        <TableCell>
                            <Text type='sm'>{building.elevators}</Text>
                        </TableCell>
                        <TableCell>
                            <View style={{width:'2rem', height:'1rem', backgroundColor: building.color}}></View>
                        </TableCell>
                        <TableCell><Text type='sm'>{!building.disable ? 'Ativo' : 'Inativo'}</Text></TableCell>
                        <TableCell>
                            <View title='Editar prédio'>
                            <a href={`/${building.companyId}/predio/${building.id}`}>
                                <Icon >edit_square</Icon>
                            </a>
                            </View>
                        </TableCell>
                    </TableRow>)
                    }
                </TableBody>
            </Table>
        </View>
    </View>
    }
    </View>
</Page>
}