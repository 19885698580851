import style from './header.module.css'
import { View } from 'components/view/view.component'
import { Text } from 'components/text/text.component'
import { Icon } from 'components/icon/icon.component'
import { useAuth } from 'services/auth.service'
import { useRef, useState } from 'react'
import { DropdownMenu } from 'components/dropdownMenu/dropdownMenu.component'
import { Button } from 'components/button/button.component'

type HeaderProps = {
    title?: string,
    onMenuClick?: ()=>void
}

export function Header({title, onMenuClick}: HeaderProps){
    const {user, logout} = useAuth()
    const [openAccountMenu, setOpenAccountMenu] = useState(false)
    const AccountButtonRef = useRef<HTMLDivElement>(null)

    function handleLogoutButton(){
        setOpenAccountMenu(false)
        logout()
    }

    return <View className={style.Header}>
        <View className={`${style.Section} ${style.Left}`}>
            <View className={`${style.Menu} ${style.IconButton}`} onClick={onMenuClick}>
                <Icon>menu</Icon>
            </View>
        </View>
        <View className={`${style.Section} ${style.Center}`}>
            {title && <Text type='h3'>{title}</Text>}
        </View>
        <View className={`${style.Section} ${style.Right}`}>
            <View className={''} onClick={()=>{window.open('https://arotech.io/#faq', '_blank')}}>
                <Text>FAQ</Text>
            </View>
            <View className={style.AccountButton} ref={AccountButtonRef} onClick={()=>{setOpenAccountMenu(!openAccountMenu)}}>
                <Text type='h5'>{user?.name ?? user?.username ?? user?.email}</Text>
                <View className={`${style.IconButton}`}>
                    <Icon fontSize='small' baseClassName='material-icons'>account_circle</Icon>
                </View>
            </View>
            <DropdownMenu anchor={AccountButtonRef} open={openAccountMenu} shouldClose={()=>setOpenAccountMenu(false)}>
                <Button onClick={handleLogoutButton}>Logout</Button>
            </DropdownMenu>
        </View>
    </View>
}