import { host } from "config/api.config"
import { Building } from "model/bulding.model"
import { useHTTPClient } from "services/httpClient.service"

const buildingUrl = `${host}/companies`

export function useGetBuildingsApi(companyId: string){
    
    const {data, loading, error, get } = useHTTPClient<Building[]>(`${buildingUrl}/${companyId}/buildings`)

    async function getApi(_companyId?: string){
        if(_companyId) console.log(`${buildingUrl}/${_companyId}/buildings`)
        else console.log(`${buildingUrl}/${companyId}/buildings`)
        if(_companyId) return await get({_url: `${buildingUrl}/${_companyId}/buildings`})
        return await get({})
    }

    return {
        loading,
        error,
        get: getApi,
        data
    }
}

export function useGetBuildingApi(companyId: string, buildingId: string){
    
    const {data, loading, error, get } = useHTTPClient<Building>(`${buildingUrl}/${companyId}/buildings/${buildingId}`)

    async function getApi(){
        if(!companyId || !buildingId) return
        return await get({})
    }

    return {
        loading,
        error,
        get: getApi,
        data
    }
}

export function useSaveNewBuildingApi(companyId: string){
    const {loading, error, post } = useHTTPClient<Building>(`${buildingUrl}/${companyId}/buildings`)
    
    async function save(data: any, _companyId?: string){
        return await post({data: data, _url: `${buildingUrl}/${_companyId ?? companyId}/buildings`})
    }
    
    return {
        loading,
        error,
        save
    }
}

export function useUpdateBuildingApi(companyId: string, id: string){
    const url = `${buildingUrl}/${companyId}/buildings/${id}`

    const {loading, error, put } = useHTTPClient<Building>(url)

    async function save(data: any, _companyId?: string, buildingId?: string){
        return await put({data: data, _url: `${buildingUrl}/${_companyId ?? companyId}/buildings/${buildingId}`})
    }

    return {
        loading,
        error,
        save
    }
}