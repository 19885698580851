import { host } from "config/api.config";
import { Company } from "model/company.model";
import { CompanyIndicators } from "model/companyIndicators.model";
import { IndicatorType } from "model/indicator.model";
import { useHTTPClient } from "services/httpClient.service";

const companyUrl = `${host}/company`

export function useGetCompanyApi(id: string){
    const {data, loading, error, get } = useHTTPClient<Company>(`${companyUrl}/${id}`)

    async function getCompany(){
        return await get({})
    }
    return {
        loading,
        error,
        get: getCompany,
        data
    }
}

export function useGetCompaniesApi(){
    const {data, loading, error, get } = useHTTPClient<Company[]>(`${companyUrl}`)

    async function getApi(){
        return await get({})
    }
    return {
        loading,
        error,
        get: getApi,
        data
    }
}

export function useSaveNewCompanyApi(){
    const {loading, error, post } = useHTTPClient<Company>(`${companyUrl}`)
    
    async function save(data: any){
        return await post({data: data})
    }
    
    return {
        loading,
        error,
        save
    }
}

export function useUpdateCompanyApi(id: string){
    const {loading, error, put } = useHTTPClient<Company>(`${companyUrl}/${id}`)

    async function save(data: any){
        return await put({data: data})
    }

    return {
        loading,
        error,
        save
    }
}

export function useGetCompanyInputIndicatorsApi(id: string){
    const url = `${companyUrl}/${id}/inputIndicators`
    const {data, loading, error, get } = useHTTPClient<IndicatorType[]>(url)
    async function getIndicators(){
        return await get({})
    }
    return {
        loading,
        error,
        get: getIndicators,
        data
    }
}

export function useGetCompanyReportIndicatorsApi(companyId: string){
    const url = `${companyUrl}/${companyId}/reportIndicators`
    const {data, loading, error, get } = useHTTPClient<IndicatorType[]>(url)
    async function getIndicators(){
        return await get({})
    }
    return {
        loading,
        error,
        get: getIndicators,
        data
    }
}

export function useGetCompanyIndicatorsApi(id: string){
    const url = `${companyUrl}/${id}/indicators`
    const {data, loading, error, get } = useHTTPClient<CompanyIndicators>(url)
    async function getIndicators(){
        return await get({})
    }
    return {
        loading,
        error,
        get: getIndicators,
        data
    }
}