import { useState, useEffect } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useHTTPClient } from "services/httpClient.service";
import { FirebaseErrors } from "data/firebase.error";
import { host } from "config/api.config";

const resetPasswordWithTokenUrl = `${host}/authentication/reset-password-with-token`

export const useAuthenticationApi = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [idToken, setIdToken] = useState<string | null>(null);
  const { post: resetPasswordWithTokenApi } = useHTTPClient(resetPasswordWithTokenUrl)

  const login = async (email: string, password: string) => {
    try {
        setLoading(true);
        const auth = getAuth();
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const _idToken = await userCredential.user.getIdToken()
        setIdToken(_idToken);
        setLoading(false);
        return Promise.resolve(_idToken);
    } catch (err: any) {
        setError(err.message);
        setLoading(false);
        const firebaseError = FirebaseErrors.find(_ => _.code === err.code)
        const returnError = {
          code: err.code ?? 'auth/unknown-error',
          message: firebaseError?.message ?? 'Erro ao realizar login'
        }
        return Promise.reject(returnError);
    }
  };

  async function resetPasswordWithToken(token: string, newPassword: string) {
    return await resetPasswordWithTokenApi({data: {code: token, newPassword: newPassword}})
  }

  useEffect(() => {
    const auth = getAuth();
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((idToken) => {
            setIdToken(idToken);
          });
      } else {
        setIdToken(null);
      }
    });
  }, []);

  return { login, loading, error, idToken, resetPasswordWithToken };
};
