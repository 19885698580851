import { host } from "config/api.config"
import { IndicatorRecordType } from "model/indicatorRecord.model"
import { PendingRecordType } from "model/pendingRecord.model"
import { useHTTPClient } from "services/httpClient.service"

const indicatorRecordUrl = `${host}/indicator-record`

export function useGetIndicatorRecordsApi(){
    const {data, loading, error, get} = useHTTPClient<IndicatorRecordType[]>(`${indicatorRecordUrl}`)
    async function getApi(filters: { buildings: string[], indicators: string[], companyId: string, startDate?: Date; endDate?: Date }){
        let filter = `?`
        if(filters.buildings) filter = `${filter}${filters.buildings.map(_ =>`buildings=${_}`).join(`&`)}`
        if(filters.indicators) filter = `${filter}${filters.indicators.map(_ =>`&indicators=${_}`).join(`&`)}`
        if(filters.companyId) filter = `${filter}&companies=${filters.companyId}`
        if(filters.startDate) filter = `${filter}&startDate=${filters.startDate.toUTCString()}`
        if(filters.endDate) filter = `${filter}&endDate=${filters.endDate.toUTCString()}`
        const _url = `${indicatorRecordUrl}${filter}`
        return await get({
            _url: _url
        })
    }
    return {
        data,
        loading,
        error,
        get: getApi
    }
}

export function useGetPendingRecordsApi(){
    const {data, loading, error, get} = useHTTPClient<PendingRecordType[]>(`${indicatorRecordUrl}/pending-records`)
    async function getApi(filters: { buildings?: string[], companyId: string, startDate?: Date; endDate?: Date }){
        let filter = `?`
        if(filters.buildings) filter = `${filter}${filters.buildings.map(_ =>`buildings=${_}`).join(`&`)}`
        if(filters.companyId) filter = `${filter}&company=${filters.companyId}`
        if(filters.startDate) filter = `${filter}&startDate=${filters.startDate.toUTCString()}`
        if(filters.endDate) filter = `${filter}&endDate=${filters.endDate.toUTCString()}`
        const _url = `${indicatorRecordUrl}/pending-records${filter}`
        return await get({
            _url: _url
        })
    }
    return {
        data,
        loading,
        error,
        get: getApi
    }
}

export function useGetIndicatorRecordApi(id: string){
    const {data, loading, error, get} = useHTTPClient<IndicatorRecordType>(`${indicatorRecordUrl}/${id}`)
    return {
        data,
        loading,
        error,
        get
    }
}

export function useSaveNewIndicatorRecord() {
    const {loading, error, post } = useHTTPClient(indicatorRecordUrl)
    async function save(indicatorRecord: Omit<IndicatorRecordType, 'id'>) {
        return await post({
            data: indicatorRecord
        })
    }
    return {
        loading,
        error,
        save
    }
}

export function useUpdateIndicatorRecord() {
    const {loading, error, put } = useHTTPClient(indicatorRecordUrl)

    async function update(indicatorRecordId: string, indicatorRecord: Partial<IndicatorRecordType>){
        await put({ data: indicatorRecord, _url: `${indicatorRecordUrl}/${indicatorRecordId}`})
    }

    return {
        loading,
        error,
        update
    }
}

export function useDeleteIndicatorRecordApi(id?: string){
    const {loading, error, _delete} = useHTTPClient<IndicatorRecordType>(`${indicatorRecordUrl}/${id}`)
    async function deleteApi(indicatorRecordId?: string){
        if(!indicatorRecordId) return await _delete({})
        return await _delete({_url: `${indicatorRecordUrl}/${indicatorRecordId}`})
    }
    return {
        loading,
        error,
        delete: deleteApi
    }
}