import { useState, useEffect, RefObject, ReactNode, useRef } from 'react';
import style from './dropdownMenu.module.css'; // Estilo CSS para o menu
import { View } from 'components/view/view.component';

interface DropdownMenuProps {
  anchor: RefObject<HTMLDivElement | HTMLButtonElement>;
  open: boolean;
  children?: ReactNode,
  shouldClose?: ()=>void
}

export function DropdownMenu({ anchor, open, children, shouldClose }: DropdownMenuProps){
  const [position, setPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const dropdownMenuRef = useRef<HTMLDivElement>(null)

  function calculatePosition(x: number, y: number){
    if(!dropdownMenuRef.current) return {x: 0, y: 0}
    const { width: menuWidth } = dropdownMenuRef.current.getBoundingClientRect();
    const { innerWidth: windowWidth } = window;
    const p = {x:x , y:y}
    if (x + menuWidth > windowWidth)  p.x = windowWidth - menuWidth 
    if (p.x < 0) p.x = 0
    return p;
  }

  useEffect(() => {
    if (anchor.current && open) {
      const rect = anchor.current.getBoundingClientRect();
      const pos = calculatePosition(rect.left, rect.bottom)
      setPosition({ x: pos.x, y: pos.y });
    }
  }, [anchor, open]);

  useEffect(()=>{
    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownMenuRef.current && !dropdownMenuRef.current.contains(event.target as Node)) {
          if(shouldClose) shouldClose()
        }
      };
  
      if (open) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    // eslint-disable-next-line
  },[open])

  return (
    open ? (
      <View
        className={style.DropdownMenu}
        style={{ top: position.y, left: position.x }}
        ref={dropdownMenuRef}
      >
        {children}
      </View>
    )
    : <></>
  );
};
